import React, { useEffect, useState } from "react";
import { Modal, Button, Row, message } from "antd";
import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import {
  getAllProducts,
  updateProductItemInStore,
} from "../../../../../../redux/actions/productActions";
// import { handleCategoryChange, hanldeProductSearch } from "../../../../../Sales/functions";
import { getAllCategories } from "../../../../../../redux/actions/categoryActions";
import { connect } from "react-redux";
import Filter from "../../../../../Sales/_partials/Filter";
import ProductList from "../../../../../Sales/_partials/ProductList";

// import "./index.scss";
import strings from "../../../../../../strings";
import BundleList from "../../../../../Sales/_partials/BundleList";


const AddProductToDraftModal = ({
    user,
    showModal,
    closeModal,
    categories,
    addProductToCart,
    addBundleToCart,
    products: product,
    showVariantModal,
    branchProducts,
    isNitroActive,
    addType,
    bundleProducts
}) =>{

    const [ searchTerm, setSearchTerm ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ products, setProducts ] = useState([]);
    const [ categoryToShow, setCategoryToShow ] = useState("")

    useEffect(()=>{
      setProducts(product);
    }, []);

    const handleCategoryChange = function (value) {
      let products = user.employer
        ? branchProducts
        : product;
    
      if (value === "all") {
        setProducts(products);
      } else {
        products = products.filter(
          (product) => product.merchant_product_category_id === value
        );

        setProducts(products);
        setCategoryToShow(value);
      }
    };

    const hanldeProductSearch = function (e) {
      const { value } = e.target;
      // console.log(value);
      if( isNitroActive ){
        setSearchTerm(value);
      }else{
        // if(value === "") return setProducts(product);
        setSearchTerm(value);
    
        
        const allProducts = user.employer
          ? branchProducts
          : product;
    
        // console.log("gk", allProducts, branchProducts, product);
        const products = allProducts.filter((product) => {
          return (
            product.name.toLowerCase().includes(value.toLowerCase())||
            (product.sku &&
              product.sku.toLowerCase().includes(value.toLowerCase())) ||
            (product.product_sku &&
              product.product_sku.toLowerCase().includes(value.toLowerCase())) ||
            (product.description &&
              product.description.toLowerCase().includes(value.toLowerCase()) )
          );
        });
      
        if (products.length === 0) {
          setProducts([]);
        } else {
          setProducts(products);
        }
      }
    };

    const handleEnterOnSearchInput = e => {
        if (e.keyCode === 13) {
          const allProducts = product;
          const currentProduct = { ...product[0] };
          if (
            products.length === 1 &&
            (currentProduct.quantity > 0 ||
              typeof currentProduct.quantity === "object") &&
            currentProduct.variants.length === 0
          ) {
            currentProduct.quantity = 1;
            setSearchTerm("");
            setProducts(allProducts);
          } else if (
            products.length === 1 &&
            currentProduct.quantity > 0 &&
            currentProduct.variants.length > 0
          ) {
            this.props.showVariantModal(currentProduct);
          }
        } else if (e.keyCode === 8 && e.target.value.length === 1) {
        }
    };
    return (
        <Modal
          title={strings.addNewProductToDraft}
          open={showModal}
          destroyOnClose
          onCancel={closeModal}
          footer={[
            <Button
              key="back"
              onClick={() => {
                closeModal();
              }}
            >
              {strings.cancel}
            </Button>,
          ]}
        >
          <div>
            <Filter
              categories={categories}
              onChangeCategory={handleCategoryChange}
              onSearch={hanldeProductSearch}
              searchTerm={searchTerm}
              onSearchEnter={handleEnterOnSearchInput}
              checkoutData={addType}
              draftModal={true}
            />
            <div
              style={{
                height: "300px",
                display: "flex",
                padding: "10px",
                flexWrap: "wrap",
                justifyContent: "space-around",
                alignItems: "center",
                overflow: "auto",
              }}
            >
              <Row
                gutter={5}
                style={{ marginRight: "0px", marginLeft: "0px" }}
                className="product-list"
              >
                <>
                  {addType === "products" && <ProductList
                    data={products}
                    addProductToCart={addProductToCart}
                    user={user}
                  />}

                  {addType === "bundle" && <BundleList
                    data={bundleProducts}
                    onClick={addBundleToCart}
                    addBundleToCart={addBundleToCart}
                    user={user}
                  />}
                </>
              </Row>
            </div>
          </div>
        </Modal>
      );
}

// class AddProductToInvoiceModal extends React.Component {
//   state = {
//     loading: false,
//     products: [],
//     searchTerm: "",
//   };

//   hanldeProductSearch = hanldeProductSearch.bind(this);
//   handleCategoryChange = handleCategoryChange.bind(this);

//   componentWillMount() {
//     this.props.getAllCategories();
//   }

//   componentDidMount() {
//     this.setState({
//       products: this.props.products,
//     });
//   }

//   hanldeEnterOnSearchInput = e => {
//     if (e.keyCode === 13) {
//       const allProducts = this.props.products;
//       const currentProduct = { ...this.state.products[0] };
//       if (
//         this.state.products.length === 1 &&
//         (currentProduct.quantity > 0 ||
//           typeof currentProduct.quantity === "object") &&
//         currentProduct.variants.length === 0
//       ) {
//         currentProduct.quantity = 1;
//         this.setState({ searchTerm: "", products: allProducts });
//       } else if (
//         this.state.products.length === 1 &&
//         currentProduct.quantity > 0 &&
//         currentProduct.variants.length > 0
//       ) {
//         this.props.showVariantModal(currentProduct);
//       }
//     } else if (e.keyCode === 8 && e.target.value.length === 1) {
//     }
//   };

//   render() {
//     const {
//       user,
//       showModal,
//       closeModal,
//       categories,
//       addProductToCart,
//     } = this.props;

//     const { products, searchTerm } = this.state;

//     return (
//       <Modal
//         title={strings.addNewProductToInvoice}
//         open={showModal}
//         destroyOnClose
//         onCancel={closeModal}
//         footer={[
//           <Button
//             key="back"
//             onClick={() => {
//               closeModal();
//             }}
//           >
//             {strings.cancel}
//           </Button>,
//         ]}
//       >
//         <div>
//           <Filter
//             categories={categories}
//             onChangeCategory={this.handleCategoryChange}
//             onSearch={hanldeProductSearch}
//             searchTerm={searchTerm}
//             onSearchEnter={this.hanldeEnterOnSearchInput}
//           />
//           <div
//             style={{
//               height: "300px",
//               display: "flex",
//               padding: "10px",
//               flexWrap: "wrap",
//               justifyContent: "space-around",
//               alignItems: "center",
//               overflow: "auto",
//             }}
//           >
//             <Row
//               gutter={5}
//               style={{ marginRight: "0px", marginLeft: "0px" }}
//               className="product-list"
//             >
//               <>
//                 <ProductList
//                   data={products}
//                   addProductToCart={addProductToCart}
//                   user={user}
//                 />
//               </>
//             </Row>
//           </div>
//         </div>
//       </Modal>
//     );
//   }
// }

AddProductToDraftModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  products: state.product.allProducts,
  bundleProducts: state.product.bundleProducts,
  categories: state.category.allCategories,
  user: state.auth.user,
  customers: state.customer.allCustomers,
  error: state.error,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
  branchProducts: state.product.branchProducts,
  isNitroActive: state.appStore.isNitroActive,
});

export default connect(mapStateToProps, {
  getAllProducts,
  updateProductItemInStore,
  getAllCategories,
})(AddProductToDraftModal);
