import { Avatar, Card, DatePicker, message, Modal } from 'antd'
import React, { useEffect, useState } from 'react';
import CustomDataTable from '../../../../../../components/CustomDataTable';
import strings from '../../../../../../strings';
import moment from 'moment';


const { RangePicker } = DatePicker;
const Transactionmodal = ({
    open,
    onCancel,
    customer,
    getCustomerSales,
    user
}) => {
    const [ customerTransactions, setCustomerTransaction ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ begin, setBegin ] = useState(null);
    const [ end, setEnd ] = useState(null);

    useEffect(() => {
        if(customer) handleGetTransaction(customer.id)
    }, [customer]);

    const handleGetTransaction = (id, begin, end) => {
        setLoading(true);
        getCustomerSales(id, begin, end)
        .then(res => {
            if(res.status === 200 || res.status === 204){
                setLoading(false);
                let transaction = [];
                res.data.length > 0 && res.data.map(trans => {
                    transaction.push(...trans.transactions)
                })
                setCustomerTransaction(transaction)
            }
        })
        .catch(err => {
            console.log("err");
            message.error(err.response?.data.error ? err.response.data.error : `Unable to get transaction - ${err.message}`)
        })
    }

    const handleDurationChange = async (duration, ...rest) => {
        if (duration) {
            const [begin, end] = duration;
            setBegin(begin);
            setEnd(end);
            handleGetTransaction(
                customer.id, 
                begin.format().split("T")[0],
                end.format().split("T")[0])
        } else if (!duration) {
            handleGetTransaction(customer.id);
        }
    };

    const openTransactionDetails = (transaction) => {
        Modal.info({
          title: strings.transactionDetails,
          content: (
            <div>
              <p>
                <strong>{strings.name}</strong>: {transaction.product_name}
              </p>
              <p>
                <strong>{strings.amount}</strong>: {transaction.amount}
              </p>
              <p>
                <strong>{strings.quantity}</strong>: {transaction.product_quantity}
              </p>
              <p>
                <strong>{strings.createdAt}</strong>:{" "}
                {moment(transaction.created_at).format("YYYY-MM-DD hh:mm:ss A")}
              </p>
              <p>
                <strong>{strings.profit}</strong>: {transaction.profit}
              </p>
              <p>
                <strong>{strings.points}</strong>: {transaction.points}
              </p>
              <p>
                <strong>{strings.redemptionToken}</strong>:{" "}
                {transaction.redemption_token}
              </p>
              <p>
                <strong>{strings.stamps}</strong>: {transaction.stamps}
              </p>
              <p>
                <strong>{strings.token}</strong>: {transaction.token}
              </p>
            </div>
          ),
        });
      };

    const columns = [
        {
          title: strings.picture,
          dataIndex: "product_picture",
          key: "product_picture",
          render: (product_picture) => (
            <div className="image">
                <Avatar size="small" shape="square" src={product_picture} alt="" />
            </div>
          ),
        },
        {
          title: strings.name,
          dataIndex: "product_name",
          key: "product_name",
        //   render: (transaction) => (
        //     transaction.map(tran => (
        //        <p style={{margin: 0, fontSize: 12}}>{tran.product_name}</p>
        //     ))
        //   ),
        },
        {
          title: strings.quantity,
          dataIndex: "product_quantity",
          key: "product_quantity",
        //   render: (transaction) => (
        //     transaction.map(tran => (
        //        <p style={{margin: 0, fontSize: 12}}>{Number(tran.quantity).toFixed(2)}</p>
        //     ))
        //   ),
          defaultSortOrder: "ascend",
          sorter: (a, b) => Number(b.amount) - Number(a.amount),
          render: (qty) => Number(qty).toFixed(2),
        },
        {
          title: strings.amount,
          dataIndex: "amount",
          key: "amount",
        //   render: (transaction) => (
        //     transaction.map(tran => (
        //        <p style={{margin: 0, fontSize: 12}}>{user.currency} {Number(tran.amount).toFixed(2)}</p>
        //     ))
        //   ),
          sorter: (a, b) => Number(b.amount) - Number(a.amount),
          render: (qty) => Number(qty).toFixed(2),
        },
        {
          title: strings.createdDate,
          dataIndex: "created_at",
          defaultSortOrder: "ascend",
          sorter: (a, b) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
          key: Math.random(),
          render: (record) =>
            record ? (
              <span>{moment(record).format("YYYY-MM-DD")}</span>
            ) : (
              <span>N/A</span>
            ),
        },
        {
          title: strings.createdTime,
          dataIndex: "created_at",
          key: Math.random(),
          defaultSortOrder: "ascend",
          sorter: (a, b) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
          render: (record) =>
            record ? (
              <span>{moment(record).format("hh:mm:ss A")}</span>
            ) : (
              <span>N/A</span>
            ),
        },
    ];
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={`${customer && customer.first_name}'s transactions`}
        width={800}
    >
         <Card
            loading={loading}
            extra={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ margin: "0 10px" }}>{strings.reportFrom}:</span>
                  <RangePicker
                    placeholder={[strings.startDate, strings.endDate]}
                    onChange={handleDurationChange}
                    allowClear="false"
                  />
                </div>
              }
        >
            <CustomDataTable 
                columns={columns}
                dataSource={customerTransactions}
                loading={loading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            openTransactionDetails(record);
                        },
                    };
                }}
            // rowSelection={rowSelection}

            />
      </Card>
    </Modal>
  )
}

export default Transactionmodal