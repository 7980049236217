import { languages } from "../languages";
const languageName = localStorage.getItem("current-language") || "English";
const language = languages.find(lang => lang.name === languageName);
const languageCode = language.language;

export default class Auth {
  constructor(client) {
    this.client = client;
  }

  signIn(userDetails) {
    const url = userDetails.email
      ? `/auth/sign_in?lang=${languageCode}`
      : `/staff_auth/sign_in?lang=${languageCode}`;
    return this.client.post(url, userDetails);
  }

  signupMerchant(merchantData) {
    return this.client.post("/auth", merchantData);
  }

  editMerchantAccount(merchantData) {
    return this.client.put(`/auth`, merchantData);
  }

  // Reset Password
  sendEmailToken(email) {
    return this.client.post("/auth/password", { email });
  }

  signOutStaff() {
    return this.client.delete("/staff_auth/sign_out");
  }

  resetMerchantPassword(merchantPasswordData) {
    return this.client.post("/merchants/reset_password", {
      data: {
        ...merchantPasswordData,
      },
      merchant: {},
    });
  }

  staffResetPassword(passwordData) {
    return this.client.put("/staff_auth/password", passwordData);
  }

  staffAdminResetPassword(data){
    return this.client.put("/merchant/staff_password_reset", {data});
  }

  checkPhoneAvailability(phone) {
    return this.client.get(`/merchants/check_phone_availability/${phone}`);
  }

  checkEmailAvailability(email) {
    return this.client.get(`/merchants/check_email_availability/${email}`);
  }

  generatePhoneVerirficationToken(phone_number) {
    return this.client.put("/generate_phone_verification_token", {
      phone_number,
    });
  }

  resendPhoneVerificationToken(phone_number) {
    return this.client.put("/generate_phone_verification_token2", {
      phone_number,
    });
  }

  verifyMerchantPhone({ phone_number, code }) {
    return this.client.put("/verify_merchant_phone_number", {
      phone_number,
      code,
    });
  }
}
