import React, { useState } from 'react';
import "./index.scss";
import strings from '../../strings';
import { InputNumber, Progress, Select, Slider } from 'antd';


const Option = Select.Option;
const MarkupPercentage = ({ value, handleChangeMArkup, user, markup_amount, handleChangeMArkupAmount }) => {
    const [ markupType, setMarkupType ] = useState(0);
    
  return (
    <div className='markup_container'>
        <div className='top_content'>
            <p>{strings.markupType}</p>

            <Select value={markupType} style={{width:"50%"}} placeholder={strings.markupType} onChange={(value) => setMarkupType(value)}>
                <Option value={0}>Percentage (%)</Option>
                <Option value={1}>Fixed Amount</Option>
            </Select>
        </div>
        {!markupType && <div className='bottom_content'>
            <div className='bottom_cont'>
                <p>{strings.markupPercentage}</p>
                {/* <p>{value || 0}%</p> */}
                <InputNumber onChange={(value) => handleChangeMArkup(value)} placeholder={value ? value : 'Input markup amount'} style={{width: "30%"}} size="middle" value={value} />
            </div>

            <Slider value={value || 0} range={false} strokeColor="#D90068" onChange={(value) => handleChangeMArkup(value)} />

            <div className='btn_div'>
                <span className={value === 10 ? "percent_btn_selected" : 'percent_btn'} onClick={() => handleChangeMArkup(10)}>10%</span>
                <span className={value === 15 ? "percent_btn_selected" : 'percent_btn'} onClick={() => handleChangeMArkup(15)}>15%</span>
                <span className={value === 20 ? "percent_btn_selected" : 'percent_btn'} onClick={() => handleChangeMArkup(20)}>20%</span>
                <span className={value === 25 ? "percent_btn_selected" : 'percent_btn'} onClick={() => handleChangeMArkup(25)}>25%</span>
                <span className={value === 30 ? "percent_btn_selected" : 'percent_btn'} onClick={() => handleChangeMArkup(30)}>30%</span>
                <span className={value === 40 ? "percent_btn_selected" : 'percent_btn'} onClick={() => handleChangeMArkup(40)}>40%</span>
                <span className={value === 50 ? "percent_btn_selected" : 'percent_btn'} onClick={() => handleChangeMArkup(50)}>50%</span>
            </div>
        </div>}
        {markupType === 1 && (
            <div className='bottom_content'>
            <div className='bottom_cont'>
                <p>{strings.markupAmount}</p>
                {/* <p>{user.currency} {markup_amount || 0}</p> */}
                <InputNumber onChange={(value) => handleChangeMArkupAmount(value)} placeholder={markup_amount ? markup_amount : 'Input markup amount'} style={{width: "30%"}} size="middle" value={markup_amount} />
            </div>

            <Slider max={10000} value={markup_amount || 0} range={false} strokeColor="#D90068" onChange={(value) => handleChangeMArkupAmount(value)} />

           
        </div>
        )}
    </div>
  )
}

export default MarkupPercentage