import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Input,
  InputNumber,
  Select,
  Switch,
  Upload,
  Button,
  Badge,
  message,
  Tooltip,
  DatePicker,
  Image
} from "antd";
import { Form, Icon } from "@ant-design/compatible";
import { LoadingOutlined } from "@ant-design/icons";
import AddCategoryModal from "../AddCategoryModal";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { getAllCategories } from "../../../../../../redux/actions/categoryActions";
import { getAllLoyaltyPrograms } from "../../../../../../redux/actions/loyaltyActions";
import {
  addNewProduct,
  getAllProducts,
  getPageProducts
} from "../../../../../../redux/actions/productActions";
import validateVariant from "../../../../../../utils/validateVariant";
import { transformInToFormObject } from "../../../../../../utils/generateFormData";
import api from "../../../../../../api";
import ValidateCustomQuantity from "../../../../../../utils/validateCustomQuantity";
// UI
import "./index.scss";
import strings from "../../../../../../strings";
import MarkupPercentage from "../../../../../../components/markupPercentage";
import { debounce } from "lodash";

const FormItem = Form.Item,
  Option = Select.Option;

const config = {
  bucketName: "loystar-android",
  region: "us-east-1",
  accessKeyId: "AKIAWOIS4FL7KHGYG6BT",
  secretAccessKey: "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",
};

const maxFileSize = 4000000;

// Image Upload URL
const url = "https://api.cloudinary.com/v1_1/loystar/image/upload";


  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

const AddProductModal = Form.create()(
  class extends Component {
    static propTypes = {
      user: PropTypes.object.isRequired,
      showModal: PropTypes.bool.isRequired,
      closeModal: PropTypes.func.isRequired,
      allLoyaltyPrograms: PropTypes.arrayOf(PropTypes.object).isRequired,
      allCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
      getAllCategories: PropTypes.func.isRequired,
      getAllLoyaltyPrograms: PropTypes.func.isRequired,
      getAllProducts: PropTypes.func.isRequired,
      addNewProduct: PropTypes.func.isRequired,
    };
    constructor(props) {
      super(props);
  
      // Create a ref
      this.inputRef = React.createRef();
    }
  
    state = {
      isAddNewCategory: false,
      showTaxedSelect: false,
      isTrackProduct: false,
      hasVariant: false,
      fileList: [],
      filesList: [],
      loading: false,
      showAddCategoryModal: false,
      variants: [],
      imageLoading: false,
      imageUploadError: false,
      imageUploadSuccess: false,
      hasCustomQuantity: false,
      customQuantity: [],
      shouldProductBePublished: true,
      filteredCategory: [],
      selected_category: null,
      // previewImage: null,
      previewOpen: false,
      displayImage: [],
      displayFiles: [],
      selectedImage: null,
      picture: null,
      markup_percentage: null,
      markup_amount: null,
      showMarkup: false,
      cost_price: null,
      searchLoading: false,
      searchOptions: []
    };

    componentDidMount() {
      this.props.getAllCategories();
      this.props.getAllLoyaltyPrograms();
      this.setCategoryfilter();
      this.fetchProducts();
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.error) {
        this.setState({ loading: false });
      }
    }

    async componentDidUpdate(prevProps){
      if(prevProps.allCategories.length !== this.props.allCategories.length)  this.setCategoryfilter()
    }

    setCategoryfilter = () => {
      const { isMyValentinePlugActive, allCategories } = this.props;

      if(isMyValentinePlugActive){
        this.setState({ filteredCategory: allCategories})
      }else{ 
        const newCategory = allCategories && allCategories.filter((prod )=> {
          return prod.name !== "Valentine for Her" 
          && prod.name !== "Valentine for Him" 
          && prod.name !== "Valentine for Couples" 
          && prod.name !== "Valentine for Myself";
        });
        this.setState({ filteredCategory: newCategory })
      }
    };

    onCategoryChange = value => {
      if (value === "add-new-category") {
        this.toggleAddCategoryModal();
      }
    };

    onLoyaltyProgramChange = value => {
      if (value === null) {
        this.props.toggleAddLoyaltyModal();
      }
    };

    handleTaxProduct = value => {
      this.setState({ showTaxedSelect: value });
      this.props.form.setFieldsValue({ tax: value });
    };

    handlePublishProduct = (value) => {
      this.setState({ shouldProductBePublished: value });
      this.props.form.setFieldsValue({ publish_to_loystar_shop: value });
    };

    handleLowStockNotification = value => {
      this.setState({ showLowStockNotification: value });
      this.props.form.setFieldsValue({ lowStockNotification: value });
    };

    handleTrackProduct = value => {
      if (this.state.hasVariant) {
        message.warn(strings.productVariantsRequireTracking);
      }

      this.setState({ isTrackProduct: value });
      this.props.form.setFieldsValue({ track_inventory: value });
    };

    handleProductVariants = value => {
      const { hasCustomQuantity } = this.state;
      if(hasCustomQuantity){
        return Modal.info({
          title: "Cannot add variants to product with custom quantity"
        })
      }else{
        this.setState({ hasVariant: value, isTrackProduct: value });
        this.props.form.setFieldsValue({
          product_variants: value,
          track_inventory: value,
        });
      }

      
    };

    handleProductCustom = value => {
      const { hasVariant } = this.state;

      if(hasVariant){
        return Modal.info({
          title: "Cannot add custom quantity to product with variant"
        })
      }

      this.setState({ hasCustomQuantity: value, isTrackProduct: value });
      this.props.form.setFieldsValue({
        has_custom_qty: value,
        track_inventory: value
      });
    };

    addVariant = () => {
      const { form } = this.props;
      const { variants, filesList } = this.state;
      form.validateFields((err, values) => {
        const {
          variant_type,
          variant_value,
          variant_price,
          variant_cost_price,
          variant_quantity,
          variant_pictures
        } = values;

        if(!variant_pictures) {
          this.setState({
            displayImage: [...this.state.displayImage, ""],
            filesList: [...this.state.filesList, ""]
          })
        };

        const result = validateVariant(
          variant_price,
          // variant_cost_price,
          variant_type,
          variant_value,
          variant_quantity
        );

        if (!result.status) {
          Modal.warning({
            title: validateVariant(
              variant_price,
              // variant_cost_price,
              variant_type,
              variant_value,
              variant_quantity
            ).message,
          });
          return;
        }

        const isAdded = variants.find(variant => {
          return variant.value.toLowerCase() === variant_value.toLowerCase();
        });
        if (isAdded) {
          Modal.warning({
            title: strings.suchVariantAlreadyExist,
          });
        } else {
          if(variant_pictures){
            this.setState({
              variants: [
                ...variants,
                {
                  value: variant_value,
                  type: variant_type,
                  price: variant_price,
                  cost_price: variant_cost_price,
                  quantity: variant_quantity,
                  picture: filesList[filesList.length - 1]
                },
              ],
            });
          }else{
            this.setState({
              variants: [
                ...variants,
                {
                  value: variant_value,
                  type: variant_type,
                  price: variant_price,
                  cost_price: variant_cost_price,
                  quantity: variant_quantity
                },
              ],
            });
          }

          // Update Product Quantity to Total Quantity of Variants
          let variantsCopy = [
            ...variants,
            {
              value: variant_value,
              type: variant_type,
              price: variant_price,
              cost_price: variant_cost_price,
              quantity: variant_quantity,
            },
          ];

          const totalVariantQty =
            variantsCopy.length &&
            variantsCopy.reduce(
              (total, variant) => total + Number(variant.quantity),
              0
            );

          totalVariantQty > 0 &&
            this.props.form.setFieldsValue({ quantity: totalVariantQty,  variant_value: "", variant_pictures: null });
          this.setState({ selectedImage: null, displayFiles: [] });
        }
      });
    };

    handleRemoveCustomQty =(value) => {
      const { customQuantity } = this.state;
      const result = customQuantity.filter(custQty => {
        return custQty.name !== value.name;
      });
      this.setState({
        customQuantity: result,
      });
    };

    displayCustomQuantity = () => {
      const { customQuantity } = this.state;

      if (customQuantity.length === 0) return strings.addCustom + "...";
      return customQuantity.map((custQty, key) => {
        const { name, price, quantity, id } = custQty;
        return (
          <li>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0 30% 0 0"}}>
              <span>{name} ({quantity} units) - {price}</span>
              <div style={{display: "flex", alignItems: "center", gap: "0.5rem", margin: "0"}}>
                {/* <Icon type="edit"  onClick={()=>this.handleEditCustom(custQty)}/> */}
                <Icon type="delete"onClick={() => {this.handleRemoveCustomQty(custQty);}}/>
              </div>
            </div>
          </li>
          // <Badge
          //   key={key}
          //   count={custQty.quantity}
          //   style={{
          //     backgroundColor: "#D90068",
          //     color: "#fffff",
          //     marginBottom: "10px",
          //   }}
          // >
          //   <Tooltip title={`${strings.costPrice}:- ${custQty.name}`}>
          //     <div className="variant">
          //       <span>{custQty.name}</span>-
          //       <span>{custQty.price ? `#${custQty.price}` : null}</span>
          //       <span
          //         className="btn delete-variant-btn"
          //         onClick={() => {
          //           this.handleRemoveCustomQty(custQty.value);
          //         }}
          //       >
          //         x
          //       </span>
          //     </div>
          //   </Tooltip>
          // </Badge>
        );
      });
    };

    uploadProps = {
      name: "file",
      action: url,
      headers: {
        body: JSON.stringify({ upload_preset: "hyemnes2", folder: "test_img" }),
      },
      onChange(info) {
        if (info.file.status === "done") {
          message.success(`${info.file.name} ${strings.fileUploadSuccessful}`);
          // setstate({ ...state, imageUrl: info.file.response.imageCloudData.url });
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} ${strings.fileUploadFailed}`);
        }
      },
    };

    handleExpiryDate = value => {
      this.setState({ isProductExpire: value });
    };

    handleDatepicker = (d, datestring, name) => {
      console.log("date", datestring);
      
      let timestamp = moment(datestring).format("YYYY-MM-DD hh:mm:ss");

      const value = moment.utc(timestamp).format();
      this.setState({ [name]: value });
    };

    handleCovertMonthsToDate = (value, name) => {
      const today = new Date();
      const result = new Date(today);
  
      const unit = value.split(" ")[1];
      const duration = Number(value.split(" ")[0]);
      if(unit === "Weeks"){
        result.setDate(result.getDate() + duration * 7);
        let timestamp = moment(result).format("YYYY-MM-DD hh:mm:ss");
        const value = moment.utc(timestamp).format();
        return this.setState({ [name]: value });      
      }

      result.setMonth(result.getMonth() + duration);
      let timestamp = moment(result).format("YYYY-MM-DD hh:mm:ss");
      const val = moment.utc(timestamp).format();
      
      return this.setState({ [name]: val }); 
    }

    addCustomQuantity = () => {
      const { form } = this.props;
      const { customQuantity } = this.state;
     
      form.validateFields(async (err, values) => {
        const {
          custom_name,
          custom_price,
          barcode,
          custom_quantity
        } = values;
        
        const result = ValidateCustomQuantity(
          custom_name,
          custom_price,
          barcode,
          custom_quantity
        );

        if (!result.status) {
          Modal.warning({
            title: ValidateCustomQuantity(
              custom_name,
              custom_price,
              barcode,
              custom_quantity,
            ).message,
          });
          return;
        }

        if(custom_name === "" || custom_quantity === "" || custom_price === ""){
          return Modal.warning({
            title: "Please input custom properties to add",
          });
        }
        const isAdded = customQuantity.find((custName) => {
          return custName.name.toLowerCase() === custom_name.toLowerCase();
        });

        if (isAdded) {
          Modal.warning({
            title: strings.suchUnitAlreadyExist,
          });
        } else {
          this.setState({
            customQuantity: [
              ...customQuantity,
              {
                barcode: barcode,
                // product_id: product.id,
                // merchant_id: product.merchant_id,
                price: custom_price,
                name: custom_name,
                quantity: custom_quantity,
              },
            ]
          });
          
          // Update Product Quantity to Total Quantity of Variants

          let customQuantityCopy = [
            ...customQuantity,
            {
              barcode: barcode,
              // product_id: product.id,
              // merchant_id: product.merchant_id,
              price: custom_price,
              name: custom_name,
              quantity: custom_quantity,
            },
          ];

          const totalCustomQuantity =
          customQuantityCopy.length &&
          customQuantityCopy.reduce(
              (total, custom) => total + Number(custom.quantity),
              0
            );

          // totalVariantQty > 0 &&
          //   this.props.form.setFieldsValue({ quantity: totalVariantQty });
        }
      });
    };

    fetchProducts = (
      page = 1,
      pageSize = 10
    ) => {
  
      // this.setState({ loadingProducts: true });
      // this.getProductCpandSp()
      return this.props.getPageProducts(page, pageSize).then((res) => {
        console.log("profduc", res.headers);
        this.setState({ loadingProducts: false, products: res.data });
        return res;
      });
    };

    handleAddProduct = () => {
      const { form, getAllProducts, addNewProduct, user } = this.props;
      
      const {
        hasVariant,
        variants,
        fileList,
        isProductExpire,
        s_expiry_date,
        s_start_expiration_notification,
        showLowStockNotification,
        hasCustomQuantity,
        shouldProductBePublished,
        filesList,
        markup_percentage
      } = this.state;

      if(this.props.total_products >= 10000 && user.subscription_plan !== "Nitro" ) return Modal.warn({title: strings.youNeedToEnableNitroPlan }) 
      form.validateFields((err, values) => {
        const { merchant_product_category_id } = values;
        
        if(merchant_product_category_id ==="add-new-category") return message.error("Please select product category!");
        
        if (err || (hasVariant && variants.length === 0)) {
          
          if(!merchant_product_category_id) return message.error("Please select product category!")
          if (hasVariant && variants.length === 0) {
            Modal.warning({
              title: strings.pleaseAddVariantClickButton,
            });
          }
          return message.error("Please fill all required fields!");
        }

        const variantTotal = variants.reduce(
          (total, variant) => total + Number(variant.quantity),
          0
        );

        if (err || variantTotal > Number(values.quantity)) {
          Modal.warning({
            title: strings.totalVariantsMoreThanSelectedQuantity,
          });
          return;
        }

        this.setState({ loading: true });

        if (hasVariant) {
          delete values.variant_type;
          delete values.variant_value;
          delete values.variant_price;
          delete values.variant_cost_price;
          delete values.variant_quantity;
          values.variants = variants.map(variant => {
            let obj = {};

            for (let key in variant) {
              if (variant[key]) {
                obj[key] = variant[key];
              }
            }

            return obj;
          });
        }

        delete values.picture;

        let payload = {
          quantity: values.quantity ? Number(values.quantity) : 0,
          price: values.price && values.price.toFixed(2),
          publish_to_loystar_shop: shouldProductBePublished,
          product_sku: values.product_sku || ""
        };

        if (values.cost_price) {
          payload.cost_price =
            values.cost_price && values.cost_price.toFixed(2);
        }

        if(markup_percentage) payload.markup_percentage = markup_percentage;
        if(values.supplier_id && values.supplier_id.length > 0){
          payload.supplier_ids = values.supplier_id;
        }

        if (values.tax_rate) {
          payload.tax_rate = values.tax_rate.toFixed(2);
        }

        if (isProductExpire) {
          payload.expiry_date = s_expiry_date;
          payload.start_expiration_notification = s_start_expiration_notification;
        }

        for (let key in values) {
          if (values[key]) {
            payload[key] = values[key];
          }
        }

        if (fileList.length) {
          payload.picture = fileList[0];
          const remainingFile = fileList.filter((file, index) => index !== 0);
          if(remainingFile.length > 0) payload.extra_pictures = [...remainingFile];
        }

        // if(filesList.length > 0){
        //   payload.variant_pictures = filesList
        // }
        
        if( hasCustomQuantity ){
          const {
            custom_name,
            custom_price,
            barcode,
            custom_quantity,
          } = values;

          if(custom_name === undefined || custom_price === undefined || custom_quantity === undefined ){
            this.setState({ loading: false });
            return Modal.warn({
              title: "Please input values for custom field"
            })
          }
        }
        const formData = transformInToFormObject(payload);
        
        this.setState({ loading: true })

        addNewProduct(formData)
          .then(res => {
            this.setState({ loading: false });

            if (res && res.status === 200) {
              const { hasCustomQuantity, customQuantity } = this.state;
              if(hasCustomQuantity){
                customQuantity.map((custom)=>{
                  const payload = {
                    name: custom.name,
                    price: custom.price,
                    quantity: custom.quantity,
                    barcode: custom.barcode,
                    product_id: res.data.id,
                    merchant_id: res.data.merchant_id
                  }
                  api.product.addCustomQuantity( payload );
                })
              }
              if (showLowStockNotification) {
                const notificationPayload = {
                  data: {
                    notification_type: values.notification_type,
                    notification_frequency: values.notification_frequency,
                    low_stock_level: values.low_stock_level,
                    product_id: res.data.id,
                    merchant_id: this.props.user.id,
                    active: true,
                  },
                };
                api.HttpClient.post(
                  "/product/stock_notification",
                  notificationPayload
                )
                  .then(res => {
                    if (res.status === 200) {
                      message.success("Product notifications set successfully");
                    }
                  })
                  .catch(err => message.error(err.message));
              }

              form.resetFields();
              this.setState({
                variants: [],
                isTrackProduct: false,
              });
              getAllProducts();
              this.props.updateProducts();

              Modal.success({
                title: strings.youveSuccessfullyAddedANewProduct,
                onOk: () => this.props.closeModal(),
              });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });

            if(err.response) {
              return Modal.error({
                title: `Unable to add product - ${err?.response?.data?.message || err?.response?.data}`
              });
            }

            Modal.error({
              title: `Unable to add product - ${err?.message}`
            });
          });
      });
    };

    handleSetCategory = (value) => {
      const { form } = this.props;
      const { getFieldsValue, setFieldsValue } = form;
      // const { merchant_product_category_id } = getFieldsValue();
      setFieldsValue({
        merchant_product_category_id: value.id
      })
      this.setState({ selected_category: value })
    };

    handleProductPicture = async (file, fileLists) => {
      const { fileList } = this.state;
      if (file.size > maxFileSize) {
        message.warning(strings.fileTooLargeLessThan4Mb);
        return;
      }

      if(fileLists.length > 4) return message.warning(strings.fileMostNotBeMoreThan4, 400);
      if(fileList.length === 4) return  message.warning(strings.fileMostNotBeMoreThan4);
      if((fileList.length + fileLists.length) > 4 ) return  message.warning(strings.fileMostNotBeMoreThan4);

      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file);
      }

      this.setState(state => {
        return { fileList: [...state.fileList, file] }
      });

      return false;
    };

    handleExtraProductPicture = async files => {
      if (files.size > maxFileSize) {
        message.warning(strings.fileTooLargeLessThan4Mb);
        return;
      }

      const formData = new FormData();
      formData.append("file", files)
      formData.append("upload_preset", uploadPreset)

      const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
      {
        method: "POST",
        body: formData
      })
    
      const parsed = await response.json();
      this.setState({ 
        displayImage: [...this.state.displayImage, parsed.secure_url], 
        filesList: [...this.state.filesList, files], 
        displayFiles: [files],
        selectedImage: parsed.secure_url 
      })

      return false;
    };

    handleRemoveProductPicture = file => {
      const index = this.state.fileList.indexOf(file);
      this.setState(state => {
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
          imageLoading: false,
        };
      });
    };

    handleRemoveExtraProductPicture = file => {
      this.setState(state => {
        const lastFile = state.filesList.pop();
        const lastImage = state.displayImage.pop();

        const newFileList = state.filesList.filter(item => item !== lastFile);
        const newDisplayImage = state.displayImage.filter(item => item !== lastImage);
        
        return {
          filesList: newFileList,
          imageLoading: false,
          displayFiles: [],
          displayImage: newDisplayImage,
          selectedImage: null
        };
      });
    };

    handleRemoveExtraProductPictureByIndex = index => {
      this.setState(state => {
        const newFileList = state.filesList.slice();
        const newDisplayImage = state.displayImage.slice();
        newFileList.splice(index, 1);
        newDisplayImage.splice(index, 1);
        return {
          filesList: newFileList,
          displayImage: newDisplayImage,
          imageLoading: false,
        };
      });
    };

    toggleAddCategoryModal = () => {
      this.setState({ showAddCategoryModal: !this.state.showAddCategoryModal });
    };

    displayVariants = () => {
      const { variants, displayImage } = this.state;

      if (variants.length === 0) return strings.addVariants + "...";
      return variants.map((variant, key) => {
        return (
          <Badge
            key={key}
            count={variant.quantity}
            style={{
              backgroundColor: "#D90068",
              color: "#fffff",
              marginBottom: "10px",
            }}
          >
            <Tooltip title={`${strings.costPrice}:- ${variant.cost_price}`}>
              <div style={{display: "flex", alignItems: "center", gap:10}}>
                <div className="variant">
                  <span>{variant.type}</span>-<span>{variant.value}</span>
                  <span>{variant.price ? `#${variant.price}` : null}</span>
                  <span
                    className="btn delete-variant-btn"
                    style={{zIndex:2}}
                    onClick={() => {
                      this.handleRemoveVariants(variant.value);
                    }}
                  >
                    x
                  </span>
                </div>
                {displayImage[key] && <div>-
                  {<Image 
                    src={displayImage.length > 0 ? displayImage[key] : null}
                    alt={variant.value}
                    height={50}
                    width={50}
                  />}
                </div>}
              </div>
            </Tooltip>
          </Badge>
        );
      });
    };

    handleRemoveVariants = value => {
      const { variants } = this.state;
      const result = variants.filter(variant => {
        return variant.value !== value;
      });
      const variantIndex = variants.indexOf(value);
      this.handleRemoveExtraProductPictureByIndex(variantIndex);
      this.setState({
        variants: result,
      });

      // Update Product Quantity to Total Quantity of Variants
      let variantsCopy = [...result];

      const totalVariantQty =
        variantsCopy.length &&
        variantsCopy.reduce(
          (total, variant) => total + Number(variant.quantity),
          0
        );

      totalVariantQty > 0 &&
        this.props.form.setFieldsValue({ quantity: totalVariantQty });
    };

    getBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    // handleSetMArginFiels = () => {
    //   const { getFieldsValue, setFieldsValue } = this.props.form;
    //   const { cost_price } = getFieldsValue();
    //   const { markup_percentage } = this.state;
    //   if(!cost_price ) return message.warn(strings.costPriceShouldBeSetToUseThisFeature);
    //   if(!markup_percentage ) return message.warn(strings.enterYourPriceMargin);

    //   const newPrice = Number(cost_price) + ((Number(markup_percentage) / 100) * Number(cost_price));
    //   setFieldsValue({
    //     price: Number(newPrice).toFixed(2)
    //   })
      
    // }

    handleChangeMArkup = (value) => {
      const { getFieldsValue, setFieldsValue } = this.props.form;
      const { cost_price } = getFieldsValue();
      if(!cost_price ) return message.warn(strings.costPriceShouldBeSetToUseThisFeature);
      // if(!value ) return message.warn(strings.enterYourPriceMargin);

      this.setState({ markup_percentage: value })

      const newPrice = Number(cost_price) + ((Number(value) / 100) * Number(cost_price));
      setFieldsValue({
        price: Number(newPrice).toFixed(2)
      })
    }

    handleChangeMArkupAmount = (value) => {
      const { getFieldsValue, setFieldsValue } = this.props.form;
      const { cost_price } = getFieldsValue();
      if(!cost_price ) return message.warn(strings.costPriceShouldBeSetToUseThisFeature);

      const percentage = (Number(value) / Number(cost_price)) * 100;
      
      this.setState({ markup_percentage: percentage.toFixed(0), markup_amount: value })

      const newPrice = Number(cost_price) + (Number(value));
      setFieldsValue({
        price: Number(newPrice).toFixed(2)
      })
    }
    
    // handleDebounce = debounce((rule, value, callback) => this.handleSearchOptions(rule, value, callback), 100);

    // handleSearchOptions = (rule, value, callback) => {
    //   if (!value) {
    //     callback("");
    //     return this.setState({ loadingProducts: false, searchOptions: [] });
    //   };
    //   this.setState({ searchLoading: true });
    //   this.inputRef.current.focus();
    //   api.HttpClient.get(`/product_quick_lookup?query=${value}`)
    //   .then((res) => {
    //     if (res.status === 200) {
    //       const availableProducts = res.data.filter((product) => product.deleted === false);
    //       this.setState({
    //         searchLoading: false,
    //         searchOptions: res.data.filter((product) => product.deleted === false),
    //       });
    //       this.inputRef.current.focus();
    //       if(availableProducts.length > 0 && availableProducts.length === 1) return callback(
    //         <p style={{cursor: "pointer"}} onClick={() => this.handleEditProduct(availableProducts[0])}>{strings.productWithThisNameAlreadyExist}</p>
    //       )

    //       if(availableProducts.length > 1) return callback(
    //         <div style={{display: "flex", flexDirection: "column", gap: 0}}>
    //           <span>{strings.multipleProductWithThisName}</span>
    //           <div style={{display: "flex", alignItems: "center", gap: 4, flexWrap: "wrap"}}>
    //             {availableProducts.map((product, i) => <span style={{cursor: "pointer"}} onClick={() => this.handleEditProduct(product)}>{product.name}{i !== availableProducts.length-1 && ", "}</span>)}
    //           </div>
    //         </div>
    //       );
          
    //     } else {
    //       callback();
    //       this.inputRef.current.focus();
    //       this.setState({ searchLoading: false, searchOptions: [] });
    //     }
    //   })
    //   .catch((err) => {
    //     callback();
    //     this.inputRef.current.focus();
    //     this.setState({ searchLoading: false });
    //     message.error(err.response?.data.message || err.message);
    //   });
    // };

    handleDebounce = debounce((value) => this.handleSearchOptions(value), 100);

    handleSearchOptions = (value) => {
      const { setFields } = this.props.form;
      if (!value) {
        // callback("");
        return this.setState({ loadingProducts: false, searchOptions: [] });
      };
      this.setState({ searchLoading: true });
      this.inputRef.current.focus();
      api.HttpClient.get(`/product_quick_lookup?query=${value}`)
      .then((res) => {
        if (res.status === 200) {
          const availableProducts = res.data.filter((product) => product.deleted === false);
          this.setState({
            searchLoading: false,
            searchOptions: res.data.filter((product) => product.deleted === false),
          });
          this.inputRef.current.focus();
          if(availableProducts.length > 0 && availableProducts.length === 1) return setFields({
            name: {
              value,
              errors: [<p style={{cursor: "pointer"}} onClick={() => this.handleEditProduct(availableProducts[0])}>{strings.productWithThisNameAlreadyExist}</p>]
            }
          })

          if(availableProducts.length > 1) return setFields({
            name: {
              value,
              errors: [ <div style={{display: "flex", flexDirection: "column", gap: 0}}>
                <span>{strings.multipleProductWithThisName}</span>
                <div style={{display: "flex", alignItems: "center", gap: 4, flexWrap: "wrap"}}>
                  {availableProducts.map((product, i) => <span style={{cursor: "pointer"}} onClick={() => this.handleEditProduct(product)}>{product.name}{i !== availableProducts.length-1 && ", "}</span>)}
                </div>
              </div>]
            }
          })
          
        } else {
          // callback();
          setFields({
            name: {
              value,
              errors: []
            }
          })
          this.inputRef.current.focus();
          this.setState({ searchLoading: false, searchOptions: [] });
        }
      })
      .catch((err) => {
        // callback();
        setFields({
          name: {
            value,
            errors: []
          }
        })
        this.inputRef.current.focus();
        this.setState({ searchLoading: false });
        message.error(err.response?.data.message || err.message);
      });
    };

    handleEditProduct = (product) => {
      const { toggleSetProductToEdit, closeModal } = this.props;
      toggleSetProductToEdit(product);
      closeModal();
    }

    render() {
      const {
        showModal,
        closeModal,
        form,
        user,
        allLoyaltyPrograms,
        allCategories,
        product_sku,
        uRewardID,
        suppliers,
        isPurchaseOrderActive
      } = this.props;

      const hint = {
        color: "orange",
        fontSize: "13px",
        marginTop: "-8px"
      };

      const { getFieldDecorator } = form;

      const { 
        showAddCategoryModal, 
        shouldProductBePublished, 
        filteredCategory, 
        previewOpen, 
        displayFiles, 
        filesList,
        selectedImage,
        fileList,
        markup_percentage,
        markup_amount,
        showMarkup,
        cost_price
      } = this.state;

      const isAdmin = user.role_id === 1;

      return (
        <React.Fragment>
          <Modal
            title={strings.addProduct}
            open={showModal}
            okText={strings.addProduct}
            cancelText={strings.cancel}
            onCancel={() => {
              this.setState({
                fileList: [],
                isTrackProduct: false,
                showTaxedSelect: false,
              });
              closeModal();
            }}
            className="add-product-modal"
            style={{ top: 20 }}
            onOk={this.handleAddProduct}
            okButtonProps={{ loading: this.state.loading }}
            destroyOnClose
            maskClosable={false}
          >
            <Form layout="vertical">
              <FormItem label={strings.productName}>
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: strings.pleaseEnterProductName,
                    },
                    // {
                    //   validator: this.handleDebounce,
                    // },
                  ],
                })(<Input onChange={(e) => this.handleDebounce(e.target.value)} ref={this.inputRef} size="large" suffix={this.state.searchLoading ? <LoadingOutlined spin /> : undefined} />)}
              </FormItem>

              <FormItem label={strings.productDescription}>
                {getFieldDecorator("description")(<Input size="large" />)}
              </FormItem>

              <FormItem label="Product SKU">
                {getFieldDecorator("product_sku", {
                  initialValue: product_sku,
                })(<Input size="large" />)}
              </FormItem>

              <FormItem label={strings.selectProductCategory}>
                {getFieldDecorator("merchant_product_category_id", {
                  rules: [
                    {
                      required: true,
                      message: strings.pleaseSelectProductCategory,
                    },
                  ],
                })(
                  <Select size="large" onChange={this.onCategoryChange} showSearch  optionFilterProp="children" placeholder={strings.searchCategory}>
                    <Option value="add-new-category">
                      {strings.addNewCategory}
                    </Option>
                    {allCategories &&
                      filteredCategory
                      .map(category => (
                        <Option value={category.id} key={category.id}>
                          {category.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>

              {isPurchaseOrderActive && <FormItem rules={[{required: true}]} label={strings.selectSuppliers} name="supplier_id">
                {getFieldDecorator("supplier_id")(<Select
                  placeholder={strings.selectSuppliers}
                  showSearch
                  optionFilterProp="children"
                  mode="multiple"
                  // value={searchedProduct}
                  // onSearch={(value) => handleProductSearch(value)}
                  size="large"
                >
                    {suppliers && suppliers.map((supplier, index) => {
                        return ( <Select.Option value={supplier.id} key={index}>{supplier.user && supplier.user.first_name} {supplier.user && supplier.user.last_name}</Select.Option>)
                    })}
                </Select>)}
              </FormItem>}

              <FormItem label={strings.selectLoyaltyProgram}>
                {getFieldDecorator("merchant_loyalty_program_id")(
                  <Select size="large" onChange={this.onLoyaltyProgramChange}>
                    <Option key="add-new-program" value={null}>
                      {strings.addNewLoyaltyProgram}
                    </Option>
                    {allLoyaltyPrograms &&
                      allLoyaltyPrograms.map(loyaltyProgram => (
                        <Option
                          value={loyaltyProgram.id}
                          key={loyaltyProgram.id}
                        >
                          {loyaltyProgram.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>

              {isAdmin && (
                <FormItem
                  label={`${strings.productCostPrice} ${
                    user.currency ? user.currency : ""
                  }`}
                >
                  {getFieldDecorator("cost_price")(
                    <InputNumber
                      formatter={value =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={value => value.replace(/\bNGN\s?|(,*)/g, "")}
                      size="large"
                      style={{ width: "100%" }}
                      onChange={(value) => this.setState({ cost_price: value })}
                    />
                  )}
                </FormItem>
              )}


            {cost_price && <p style={{color: "#D90068", cursor: "pointer"}} onClick={() => {
              if(this.state.showMarkup) this.setState({ markup_percentage: null })
              this.setState({ showMarkup: !this.state.showMarkup })
              }}>{showMarkup ? strings.hideMarkupPercent : strings.showMarkupPercent}</p>}

            {showMarkup && isAdmin && <FormItem >
              {/* <InputNumber value={markup_percentage} style={{width: "100%"}} size="large" onChange={this.handleChangeMArkup} placeholder="%" /> */}
              <MarkupPercentage value={markup_percentage} handleChangeMArkup={this.handleChangeMArkup} user={user} markup_amount={markup_amount} handleChangeMArkupAmount={this.handleChangeMArkupAmount} />
            </FormItem>}


              <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                <FormItem
                  label={`${strings.productSellingPrice} ${
                    user.currency ? user.currency : ""
                  }`}
                >
                  {getFieldDecorator("price", {
                    rules: [
                      {
                        required: true,
                        message: strings.pleaseEnterProductSellingPrice,
                      },
                    ],
                  })(
                    <InputNumber
                      formatter={value =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={value => value.replace(/\bNGN\s?|(,*)/g, "")}
                      size="large"
                      style={{ width: "100%" }}
                      disabled={markup_percentage}
                    />
                  )}
                </FormItem>


                {isAdmin && <div className="">
                  

                  {/* <Button style={{height: 38}} type="primary" onClick={() => this.handleSetMArginFiels()}>{strings.addMArgin}</Button> */}
                </div>}
              </div>

              <FormItem label={strings.productImage}>
                {getFieldDecorator("picture")(
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap:5,
                      // justifyContent: "space-between",
                      width: "100%",
                      // alignItems: "center",
                    }}
                  >
                    <Upload
                      // {...this.uploadProps}
                      fileList={fileList}
                      beforeUpload={this.handleProductPicture}
                      onRemove={this.handleRemoveProductPicture}
                      multiple={fileList.length === 4 ? false : true}
                      accept=".jpg, .png, .jpeg, .gif, .tiff, .tif"
                      style={{width: "50%", display: "flex"}}
                      previewFile={null}
                      
                    >
                      <Button>
                        <Icon type="upload" /> {strings.selectProductImage}
                      </Button>
                      <p style={{...hint, marginTop: -2}}>
                        <p>
                          {strings.youCanNowAddUpToFourImages}
                        </p>
                      </p>

                    </Upload>
                    {fileList.length > 0 && (
                      <div style={{display: "flex", alignItems: "center", gap:15, marginBottom: 20}}>
                        {fileList.map((file, index) => {
                          return (
                            <div style={{position: "relative"}}>
                              <Image
                                src={file.preview}
                                alt="images"
                                key={index}
                                height={60}
                                width={60}
                              />
                              <Icon 
                                type="close" 
                                style={{
                                  position: "absolute", 
                                  right: 5, 
                                  cursor: "pointer"
                                }}  
                                onClick={() => this.handleRemoveProductPicture(file)}
                              />
                            </div>
                            
                          )
                        })}
                      </div>
                    )}
                  </div>
                )}
              </FormItem>
              
              <FormItem label={strings.publish}>
                {getFieldDecorator("publish_to_loystar_shop")(
                  // <Checkbox
                  //   checked={shouldProductBePublished}
                  //   onChange={this.handlePublishProduct}
                  //   style={{scale: "1.5", marginLeft: "0.3rem"}}
                  // />
                  <Switch
                    checked={shouldProductBePublished}
                    onChange={this.handlePublishProduct}
                  />
                )}
              </FormItem>
              <p style={hint}>
                      {user.currency === "NGN"
                        ? <p>This item would be made are available for online orders on your Loystar Shop link - <a href={`${process.env.REACT_APP_UREWARDS_BASE_URL}/${uRewardID}`} target="_blank" rel="noopener">{process.env.REACT_APP_UREWARDS_BASE_URL}/{uRewardID}</a></p>
                        : ""}
                </p>
              <FormItem label={strings.doYouWantToTrackProductStock}>
                {getFieldDecorator("track_inventory")(
                  <Switch
                    onChange={this.handleTrackProduct}
                    checked={this.state.isTrackProduct || this.state.hasVariant}
                  />
                )}
              </FormItem>

              {this.state.isTrackProduct || this.state.hasVariant ? (
                <>
                  <FormItem label={strings.stockUnit}>
                    {getFieldDecorator("unit", {
                      rules: [
                        {
                          required: this.state.isTrackProduct,
                          message: strings.pleaseSelectStockUnit,
                        },
                      ],
                    })(
                      <Select size="large" showSearch  optionFilterProp="children">
                        <Option value="bag">{strings.bag}</Option>
                        <Option value="bottles">{strings.bottle}</Option>
                        <Option value="bunch">{strings.bunch}</Option>
                        <Option value="can">{strings.can}</Option>
                        <Option value="carton">{strings.carton}</Option>
                        <Option value="crate">{strings.crate}</Option>
                        <Option value="cup">{strings.cup}</Option>
                        <Option value="dozen">{strings.dozen}</Option>
                        <Option value="gigabyte">{strings.gigabytes}</Option>
                        <Option value="gram">{strings.gram}</Option>
                        <Option value="kg">{strings.kilogram}</Option>
                        <Option value="litre">{strings.litre}</Option>
                        <Option value="pack">{strings.pack}</Option>
                        <Option value="pair">{strings.pair}</Option>
                        <Option value="pcs">{strings.pieces}</Option>
                        <Option value="plate">{strings.plate}</Option>
                        <Option value="tonne">{strings.tonne}</Option>
                        <Option value="unit">{strings.uNIT}</Option>
                        <Option value="yard">{strings.yard}</Option>
                        <Option value="satchet">{strings.satchet}</Option>
                        <Option value="tablet">{strings.tablet}</Option>
                      </Select>
                    )}
                  </FormItem>

                  <FormItem label={strings.productQuantity}>
                    {getFieldDecorator("quantity", {
                      rules: [
                        {
                          required: this.state.isTrackProduct,
                          message: strings.pleaseEnterProductQuantity,
                        },
                        // {
                        //   min: 1,
                        //   message: "Quantity cannot be negative value",
                        // },
                      ],
                    })(
                      <InputNumber
                        disabled={this.state.hasVariant}
                        size="large"
                        style={{ width: "100%" }}
                        min={0}
                      />
                    )}
                  </FormItem>
                </>
              ) : null}

              <FormItem label={strings.doesProductHaveVariants}>
                {getFieldDecorator("product_variants")(
                  <Switch onChange={this.handleProductVariants} />
                )}
              </FormItem>

              {this.state.hasVariant ? (
                <>
                  {this.state.variants.length > 0 && (<div className="variant-container">
                    {this.displayVariants()}
                  </div>)}

                  <div style={{ marginTop: "2rem" }}>
                    <div className="row-wrapper">
                      <FormItem label={strings.type}>
                        {getFieldDecorator("variant_type", {
                          rules: [
                            {
                              required: false,
                              message: strings.pleaseEnterVariantType,
                            },
                          ],
                        })(<Input size="large" />)}
                      </FormItem>

                      <FormItem label={strings.value}>
                        {getFieldDecorator("variant_value", {
                          rules: [
                            {
                              required: false,
                              message: strings.pleaseEnterVariantValue,
                            },
                          ],
                        })(<Input size="large" />)}
                      </FormItem>
                    </div>
                    
                    <div className="row-wrapper">
                      <FormItem label={strings.sellingPrice}>
                        {getFieldDecorator("variant_price", {
                          rules: [
                            {
                              required: false,
                              message: strings.pleaseEnterVariantPrice,
                            },
                          ],
                        })(
                          <InputNumber
                            formatter={value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={value =>
                              value.replace(/\bNGN\s?|(,*)/g, "")
                            }
                            size="large"
                          />
                        )}
                      </FormItem>

                      {isAdmin && (
                        <FormItem label={strings.costPrice}>
                          {getFieldDecorator("variant_cost_price")(
                            <InputNumber
                              formatter={value =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={value =>
                                value.replace(/\bNGN\s?|(,*)/g, "")
                              }
                              size="large"
                            />
                          )}
                        </FormItem>
                      )}
                    </div>

                    <div className="row-wrapper">
                    <div className="row-wrapper" style={{display: "flex", flexDirection: "column", gap: 2}}>
                      <FormItem label={strings.variantImage}>
                        {getFieldDecorator("variant_pictures")(
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <Upload
                              // {...this.uploadProps}
                              fileList={displayFiles}
                              beforeUpload={this.handleExtraProductPicture}
                              // multiple
                              onRemove={this.handleRemoveExtraProductPicture}
                              // onPreview={this.handlePreview}
                              accept=".jpg, .png, .jpeg, .gif, .tiff, .tif, .svg"
                            >
                              <Button>
                                <Icon type="upload" /> {strings.selectVariantImage}
                              </Button>
                            </Upload>
                          </div>
                        )}
                      </FormItem>
                      {selectedImage && (
                        <Image
                          src={selectedImage}
                          alt="variant_image"
                          height={70}
                          width={70}
                        />
                      )}
                      </div>

                      <FormItem label={strings.quantity}>
                        {getFieldDecorator("variant_quantity", {
                          rules: [
                            {
                              required: false,
                              message: strings.pleaseEnterVariantQuantity,
                            },
                          ],
                        })(<InputNumber size="large" />)}
                      </FormItem>
                    </div>

                   
                    <div style={{marginTop: 5}}>
                    {/* <FormItem
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          width: "47%",
                        }}
                      > */} 
                        <Button
                          type="primary"
                          htmlType="button"
                          onClick={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();
                            this.addVariant();
                          }}
                        >
                          {strings.addVariant}
                        </Button>
                      {/* </FormItem> */}
                    </div>
                  </div>
                </>
              ) : null}

              <FormItem label={strings.doesProductHaveCustom}>
                {getFieldDecorator("has_custom_qty")(
                  <Switch onChange={this.handleProductCustom} />
                )}
              </FormItem>

              {this.state.hasCustomQuantity && !this.state.hasVariant ? (
                <>
                  <div style={{ marginTop: "0" }}>
                    <div className="">
                      <h4>Custom quantities:</h4>
                      <ul>{this.displayCustomQuantity()}</ul>
                    </div>
                    <div className="row-wrapper">
                      <FormItem label={strings.customName}>
                        {getFieldDecorator("custom_name", {
                          // rules: [
                          //   {
                          //     required: true,
                          //     message: strings.pleaseEnterCustomName,
                          //   },
                          // ],
                        })(<Input size="large" />)}
                      </FormItem>

                      <FormItem label={strings.customPrice}>
                        {getFieldDecorator("custom_price", {
                          // rules: [
                          //   {
                          //     required: true,
                          //     message: strings.pleaseEnterCustomPrice,
                          //   },
                          // ],
                        })(<InputNumber size="large" />)}
                      </FormItem>
                    </div>

                    <div className="row-wrapper">
                      <FormItem label={"barcode"}>
                          {getFieldDecorator("barcode", {
                            initialValue: product_sku,
                          })(<Input size="large" />)}
                      </FormItem>

                      {/* <FormItem label={"product id"}>
                          {getFieldDecorator("custom_product_id", {
                            rules: [
                              {
                                required: true,
                                message: "Please Enter Product Id",
                              },
                              
                            ],
                          })(<Input size="large" readOnly />)}
                      </FormItem> */}
                    </div>

                    <div className="row-wrapper">
                      <FormItem label={strings.quantity}>
                        {getFieldDecorator("custom_quantity", {
                          // rules: [
                          //   {
                          //     required: false,
                          //     message: strings.pleaseEnterVariantQuantity,
                          //   },
                          // ],
                        })(<InputNumber size="large" />)}
                      </FormItem>

                      <FormItem
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          width: "47%",
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="button"
                          onClick={this.addCustomQuantity}
                        >
                          {strings.add}
                        </Button>
                      </FormItem>
                    </div>
                  </div>
                </>
              ) : null}

              <FormItem label={strings.isThisProductTaxed}>
                {getFieldDecorator("tax")(
                  <Switch onChange={this.handleTaxProduct} />
                )}
              </FormItem>

              {this.state.showTaxedSelect ? (
                <>
                  <FormItem label={strings.selectTaxType}>
                    {getFieldDecorator("tax_type", {
                      rules: [
                        {
                          required: true,
                          message: strings.pleaseSelectTaxType,
                        },
                      ],
                    })(
                      <Select size="large">
                        <Option value="VAT">VAT</Option>
                        <Option value="PROGRESSIVE">
                          {strings.progressive}
                        </Option>
                        <Option value="PROPORTIONAL">
                          {strings.proportional}
                        </Option>
                      </Select>
                    )}
                  </FormItem>

                  <FormItem label={strings.taxRate}>
                    {getFieldDecorator("tax_rate", {
                      rules: [
                        {
                          required: this.state.showTaxedSelect,
                          message: strings.pleaseEnterProductTaxRate,
                        },
                      ],
                    })(<InputNumber size="large" style={{ width: "100%" }} />)}
                  </FormItem>
                </>
              ) : null}

              <FormItem label={strings.setProductExpiryReminder}>
                {getFieldDecorator("isProductExpire")(
                  <Switch
                    onChange={this.handleExpiryDate}
                    checked={this.state.isProductExpire}
                  />
                )}
              </FormItem>
              {this.state.isProductExpire ? (
                <div className="row-wrapper">
                  <FormItem label={strings.productExpiryDate} style={{ width: "50%" }}>
                    <DatePicker
                      size="large"
                      style={{ width: "100%" }}
                      format="YYYY-MM-DD"
                      onChange={(d, dateString) =>
                        this.handleDatepicker(d, dateString, "s_expiry_date")
                      }
                    />
                  </FormItem>
                  <FormItem label={strings.startRemindingFrom} style={{ width: "50%" }}>
                    {/* <DatePicker
                      size="large"
                      style={{ width: "100%" }}
                      format="YYYY-MM-DD"
                      onChange={(d, dateString) =>
                        this.handleDatepicker(
                          d,
                          dateString,
                          "s_start_expiration_notification"
                        )
                      }
                    /> */}
                    <Select placeholder={strings.startRemindingFrom} size="large" style={{ width: "100%" }} onChange={(value) => this.handleCovertMonthsToDate(value,"s_start_expiration_notification")}>
                      <Select.Option value={strings.twoWeeks}>{strings.twoWeeks}</Select.Option>
                      <Select.Option value={strings.oneMonth}>{strings.oneMonth}</Select.Option>
                      <Select.Option value={strings.threeMonth}>{strings.threeMonth}</Select.Option>
                      <Select.Option value={strings.sixMonth}>{strings.sixMonth}</Select.Option>
                    </Select>
                  </FormItem>
                </div>
              ) : null}

              <FormItem label="Send low stock notifications?">
                {getFieldDecorator("low_stock_notification")(
                  <Switch onChange={this.handleLowStockNotification} />
                )}
              </FormItem>

              {this.state.showLowStockNotification ? (
                <>
                  <div className="row-wrapper">
                    <FormItem label="Select Notification Type">
                      {getFieldDecorator("notification_type", {
                        rules: [
                          {
                            required: true,
                            message: "Please select notification type",
                          },
                        ],
                      })(
                        <Select size="large">
                          <Option value="email">Email only</Option>
                          <Option value="sms">SMS only</Option>
                          <Option value="both">Email and SMS</Option>
                        </Select>
                      )}
                    </FormItem>

                    <FormItem label="Notification Frequency">
                      {getFieldDecorator("notification_frequency", {
                        rules: [
                          {
                            required: this.state.showLowStockNotification,
                            message: "Please select notification frequency",
                          },
                        ],
                      })(
                        <Select size="large">
                          <Option value="once">Once</Option>
                          <Option value="until_restocked">
                            Everyday until restocked
                          </Option>
                        </Select>
                      )}
                    </FormItem>
                  </div>

                  <FormItem label="Low Stock Level">
                    {getFieldDecorator("low_stock_level", {
                      rules: [
                        {
                          required: this.state.showLowStockNotification,
                          message:
                            "Please enter stock level to start notification",
                        },
                      ],
                    })(<InputNumber size="large" style={{ width: "100%" }} />)}
                  </FormItem>
                </>
              ) : null}
              {/* <FormItem label={strings.publish}>
                {getFieldDecorator("publish_to_loystar_shop")(
                  <Checkbox
                    checked={shouldProductBePublished}
                    // defaultChecked={}
                    onChange={this.handlePublishProduct}
                    style={{scale: "1.5", marginLeft: "0.3rem"}}
                  />
                )}
              </FormItem> */}
            </Form>
          </Modal>
          <AddCategoryModal
            toggleModal={this.toggleAddCategoryModal}
            showAddCategoryModal={showAddCategoryModal}
            setCategoryfilter={this.setCategoryfilter}
            handleSetCategory={this.handleSetCategory}
          />
        </React.Fragment>
      );
    }
  }
);

const mapStateToProps = state => ({
  user: state.auth.user,
  allCategories: state.category.allCategories,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
  isMyValentinePlugActive: state.appStore.isMyValentinePlugActive,
});

export default connect(mapStateToProps, {
  getAllCategories,
  getAllLoyaltyPrograms,
  addNewProduct,
  getAllProducts,
  getPageProducts
})(AddProductModal);
