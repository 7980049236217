import React from "react";
import { Modal, InputNumber, Select } from "antd";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";

//Redux
import { connect } from "react-redux";
import { getAllBusinessBranches } from "../../../../../../redux/actions/branchActions";
import {
  sendInventory,
  getAllProducts,
  sendInventoryVariants,
} from "../../../../../../redux/actions/productActions";
import strings from "../../../../../../strings";

const Option = Select.Option;

class SendInventoryModal extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.props.getAllBusinessBranches();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ loading: false });
    }
  }

  checkQuantity = (value, totalQuantity) => {
    console.log("vaka", value.quantity, totalQuantity);
    return Number(value.quantity) < Number(totalQuantity)
  }

  handleSendInventory = () => {
    const {
      form,
      product,
      sendInventory,
      updateProducts,
      closeModal,
      sendInventoryVariants
    } = this.props;

    form.validateFields((err, values) => {
      if (err) return;

      this.setState({ loading: true });

      let requestPayload;
      if(product.variants.length > 0){
        const selectedVariant = product.variants.filter(variant => values.variant_id.includes(variant.id));
        let variantArray = [];

        selectedVariant.map(val => variantArray.push({
          variant_id: val.id,
          product_id: product.id,
          type: val.type,
          value: val.value,
          quantity: values.quantity,
          business_branch_id: values.business_branch_id,
          cost_price: val.cost_price,
          price: val.price
        }));

        if(selectedVariant.some(value => Number(value.quantity) < Number(values.quantity))){
          this.setState({ loading: false });
          return Modal.warning({
            title: strings.youCantSendMoreThanStock,
          });
        }
        requestPayload = [...variantArray];
      }else{
        requestPayload = { 
          ...values, 
          product_id: product.id 
        };
      }

      // validate product quantity to send
      if (values.quantity > product.quantity) {
        Modal.warning({
          title: strings.youCantSendMoreThanStock,
        });

        this.setState({ loading: false });
      } else {
        if(product.variants.length > 0){
          sendInventoryVariants(requestPayload)
          .then(res => {
            if (res && res.status === 200) {
              this.setState({ loading: false });
              form.resetFields();
  
              Modal.success({
                title: strings.inventorySentSuccessfully,
              });
  
              updateProducts();
              closeModal();
            }
          })
        }else{
          sendInventory(requestPayload).then(res => {
            if (res && res.status === 200) {
              this.setState({ loading: false });
              form.resetFields();
  
              Modal.success({
                title: strings.inventorySentSuccessfully,
              });
  
              updateProducts();
              closeModal();
            }
          });
        }
      }
    });
  };

  render() {
    const {
      showModal,
      closeModal,
      form: { getFieldDecorator },
      product,
    } = this.props;

    const branches = this.props.branches.filter(
      branch => branch.name.toLowerCase() !== "ecommerce"
    );

    return (
      <Modal
        open={showModal}
        onCancel={closeModal}
        okText={strings.sendInventory}
        cancelText={strings.cancel}
        title={`${strings.send} ${product.name}`}
        destoryOnClose
        okButtonProps={{ loading: this.state.loading }}
        onOk={this.handleSendInventory}
      >
        <Form layout="vertical" >
          <Form.Item label={strings.quantity}>
            {getFieldDecorator("quantity", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseEnterQuantityToSend,
                },
              ],
            })(<InputNumber size="large" style={{ width: "100%" }} />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("business_branch_id", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseSelectABranch,
                },
              ],
            })(
              <Select
                showSearch
                placeholder={strings.searchForBranch}
                optionFilterProp="children"
                size="large"
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {branches &&
                  branches.map(branch => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>

         {product.variants.length > 0 && <Form.Item>
            {getFieldDecorator("variant_id", {
              rules: [
                {
                  required: true,
                  message: strings.pleaseSelectAVariant,
                },
              ],
            })(
              <Select
                showSearch
                placeholder={strings.searchForVariant}
                optionFilterProp="children"
                size="large"
                style={{ width: "100%" }}
                mode="multiple"
                // filterOption={(input, option) =>
                //   option.props.children
                //     .toLowerCase()
                //     .indexOf(input.toLowerCase()) >= 0
                // }
              >
                {product.variants &&
                  product.variants.map(variant => (
                    <Option key={variant.id} value={variant.id}>
                      {variant.type} - {variant.value}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>}
        </Form>
      </Modal>
    );
  }
}

const SendInventoryForm = Form.create()(SendInventoryModal);

SendInventoryForm.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string.isRequired,
  getAllBusinessBranches: PropTypes.func.isRequired,
  sendInventory: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  branches: state.branch.allBranches,
  error: state.error,
});

export default connect(mapStateToProps, {
  getAllBusinessBranches,
  sendInventory,
  getAllProducts,
  sendInventoryVariants
})(SendInventoryForm);
