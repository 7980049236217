import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button, Popover, Dropdown } from "antd";
import SalesBill from "../../../../../Sales/_partials/SalesBill";
import { getAllCustomers } from "../../../../../../redux/actions/customerActions";
import ReactToPrint from "react-to-print";
import CartList from "../../../../../Sales/_partials/CartList";
import strings from "../../../../../../strings";
import AddProductToDraftModal from "../ProductModal";
import { updateProductItemInStore } from "../../../../../../redux/actions/productActions";
import { 
  addProductToCart, 
  decreaseItemQuantity, 
  removeItemFromCart,
  handleAddBundleProductToCart,
  handleRemoveBundleProductToCart,
  handleReduceBundleProductToCart 
} from "../../functions";

class ViewDraftDetails extends Component {


  state = {
    isProductModalOpen: false,
    isEdit: false,
    cartList: [],
    addType: "products"
  }

  addProductToCart = addProductToCart.bind(this);
  removeItemFromCart = removeItemFromCart.bind(this);
  decreaseItemQuantity = decreaseItemQuantity.bind(this);
  handleAddBundleProductToCart = handleAddBundleProductToCart.bind(this);
  handleReduceBundleProductToCart = handleReduceBundleProductToCart.bind(this);
  handleRemoveBundleProductToCart = handleRemoveBundleProductToCart.bind(this);

  componentDidMount (){
    const {
      transaction,
      selectedCustomer,
      customer,
      id,
    } = this.props.currentBill;

    this.setState({ cartList: transaction });
  }

  handleSaveDraft = () => {
    const { updateDraftSales, closeModal } = this.props;
    updateDraftSales();
    closeModal();
  }
  handleDraftPayment = () => {
    const {
      transaction,
      selectedCustomer,
      customer,
      id,
    } = this.props.currentBill;

    this.props.history.push({
      pathname: "/sales",
      state: {
        fromDraftSales: true,
        transaction,
        selectedCustomer: selectedCustomer ? selectedCustomer : "",
        customerValue: customer && customer.user_id ? customer.user_id : null,
        bill_id: id,
      },
    });
  };

  render() {
    const {
      isViewDraftModalOpen,
      toggleDeleteDraftModal,
      closeModal,
      currentBill,
      user,
    } = this.props;

    const { isProductModalOpen, isEdit, cartList, addType } = this.state;

    const {
      total,
      transaction,
      selectedCustomer,
      formattedCustomerName,
      customer,
      staff,
      allLoyaltyPrograms,
      created_at,
    } = currentBill;

    const transactionDate = new Date(created_at);
    const timeOfTransaction = transactionDate.toLocaleTimeString();
    const date_s = transactionDate.toLocaleDateString();
    const isAdminIsManager =
    user.role_id === 1 ||
    (user.role && user.role.id === 2);
    const isAdmin = user.role_id && user.role_id === 1; 

    let twoDigitMonth =
      transactionDate.getMonth() + 1 >= 10
        ? transactionDate.getMonth() + 1
        : "0" + (transactionDate.getMonth() + 1);

    let twoDigitDate =
      transactionDate.getDate() >= 10
        ? transactionDate.getDate()
        : "0" + transactionDate.getDate();

    const dateSplits = date_s.split("/");

    const [mm, dd, yy] = dateSplits;

    const dateOfTransaction = `${dd}/${mm}/${yy}`;

    const formatCustomer_name = formattedCustomerName.split("@");

    // const isAdminIsManager =
    //   user.role_id === 1 || (user.role && user.role.id === 2);
    const draftSalesList = JSON.parse(localStorage.getItem("draftSales"));
    draftSalesList.filter(item => item.id === this.props.currentBill.id);

    const dropdownItem = [
          {
            key: 1,
            label: (
              <Button
                type="primary"
                onClick={()=>this.setState({ isProductModalOpen: true, addType: "products"}) }
              >
                Add Products
              </Button>
          ),
        },
        {
          key: 2,
          label: (
            <Button 
            onClick={()=>this.setState({ isProductModalOpen: true, addType: "bundle"}) }
            >
              Add Bundles
            </Button>
          ),
        }
        ]

    return (
      <><Modal
        title={strings.billDetails}
        cancelText={strings.cancel}
        open={isViewDraftModalOpen}
        destroyOnClose
        onCancel={closeModal}
        footer={[
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            justifyContent: "right"
          }}>
            {isAdmin && <Button onClick={() => toggleDeleteDraftModal()}>
              {strings.delete}
            </Button>}
            {isEdit && <Button
              onClick={this.handleSaveDraft}
            >
              Save bill
            </Button>}
            <ReactToPrint
              trigger={() => (
                <Button style={{margin: 0}}>
                  {strings.printBill}
                </Button>
              )}
              content={() => this.componentRef}
            />
            <div style={{ display: "none" }}>
              <SalesBill
                currency={user.currency || user.employer.currency || this.props.currency}
                saleTotal={total}
                transaction={transaction}
                purchaseTime={`${twoDigitDate} - ${twoDigitMonth} - ${transactionDate.getFullYear()} ${transactionDate.getHours()}:${transactionDate.getMinutes()}:${transactionDate.getSeconds()}`}
                user={user}
                selectedCustomer={
                  this.props.currentBill.selectedCustomer !== ""
                    ? this.props.currentBill.selectedCustomer
                    : formatCustomer_name[0]
                }
                customer={customer}
                allLoyaltyPrograms={allLoyaltyPrograms}
                ref={el => (this.componentRef = el)}
                // customerList={customers}
                discountAmount={draftSalesList[0].discountAmount}
                hasDiscount={draftSalesList[0].hasDiscount}
                //
                customerName={
                  this.props.currentBill.selectedCustomer !== ""
                    ? this.props.currentBill.selectedCustomer
                    : formatCustomer_name[0]
                }
                timeOfTransaction={timeOfTransaction}
                dateOfTransaction={dateOfTransaction}
              />
            </div>
            <Button
              onClick={this.handleDraftPayment}
              type="primary"
            >
              Pay
            </Button>
          </div>
          ,
          // <Popover
          //   title={strings.actions}
          //   content={
              
          //   }
          // >
            // <Button type="primary">{strings.actions}</Button>
          // </Popover>,
        ]}
      >
        <div>
          <p>
            <strong>{strings.customerName}</strong>: {formattedCustomerName}
          </p>

          <p>
            <strong>{strings.servedBy}</strong>: {staff ? staff : strings.admin}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <p>
              <strong>{strings.products}</strong>
            </p>
            {isEdit && <Dropdown  menu={{ items: dropdownItem }}>
                <Button
                  // icon={<PlusOutlined />}
                  size="default"
                  type="primary"
                >
                  Add Products
                </Button>
            </Dropdown>}
            {!isEdit && (
              <Button
                size="default"
                type="primary"
                onClick={() => this.setState({ isEdit: true })}
              >
                Edit products
              </Button>
            )}
          </div>
          <div className="cart details-cart">
            {cartList && cartList.length && (
              <CartList
                data={cartList}
                increaseItemQuantity={isEdit ? this.addProductToCart : null}
                removeItemFromCart={isEdit ? this.removeItemFromCart : null}
                reduceItemFromCart={isEdit ? this.decreaseItemQuantity : null}
                handleShowBulkModal={null}
                addBundleToCart={this.handleAddBundleProductToCart}
                reduceBundleFromCart={this.handleReduceBundleProductToCart}
                removeBundleFromcart={this.handleRemoveBundleProductToCart}
              />
            )}
          </div>
          <p>
            <strong>{strings.total}</strong>: {total || "-"}
          </p>
        </div>
      </Modal>
      {isProductModalOpen && 
        <AddProductToDraftModal 
          addProductToCart={this.addProductToCart}
          addBundleToCart={this.handleAddBundleProductToCart}
          addType={addType}
          showModal={isProductModalOpen}
          closeModal={()=>this.setState({ isProductModalOpen: false })}
        />
      }
      </>
    );
  }
}

const mapStateToprops = state => ({
  customers: state.customer.allCustomers,
  user: state.auth.user,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
  products: state.product.allProducts,
});

export default connect(mapStateToprops, { getAllCustomers, updateProductItemInStore })(ViewDraftDetails);
