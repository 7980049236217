import React from "react";
import { message } from "antd";

import cloneArray from "../../../../utils/cloneArray";
import strings from "../../../../strings";

const toastMessageDuration = 5; // sec


export const removeItemFromCart = function (item) {
  const { cartList } = this.state;
  const { user, currentBill } = this.props;
  const draftSales = JSON.parse(localStorage.getItem("draftSales"));
  const userDraftSale = draftSales.filter(draft => draft.merchant_id === user.id);
  const otherDraftSale = userDraftSale.filter(sale => sale.id !== currentBill.id);
  const draftSale = userDraftSale.filter(sale => sale.id === currentBill.id);

  let newList;
  if (item.variants && item.variants.length > 0) {
    item.variants.sort((a, b) => {
      return a.id < b.id;
    });
    newList = cartList.filter((cartItem) => {
      if (cartItem.variants.length > 0) {
        cartItem.variants.sort((a, b) => {
          return a.id < b.id;
        });

        if (item.id !== cartItem.id) {
          return item.id !== cartItem.id;
        } else if (
          item.id === cartItem.id &&
          JSON.stringify(item.variants) !== JSON.stringify(cartItem.variants)
        ) {
          return (
            JSON.stringify(item.variants) !== JSON.stringify(cartItem.variants)
          );
        }
      }
      return item.id !== cartItem.id;
    });
  } else {
    newList = cartList.filter((product) => {
      return product.id !== item.id;
    });
  }
  this.setState({
    cartList: [...newList],
  });

  let list = [...newList ]
  const total = list.reduce((prev, curr)=>{
    return prev + Number(curr.amount) 
  }, 0);
  draftSale[0].total = total;
  draftSale[0].transaction = [ ...newList ];
  const newDraftSale = [ ...otherDraftSale, ...draftSale];
  localStorage.setItem("draftSales", JSON.stringify(newDraftSale));
};

export const decreaseItemQuantity = function (item) {
  const { user, currentBill } = this.props;
  const draftSales = JSON.parse(localStorage.getItem("draftSales"));
  const userDraftSale = draftSales.filter(draft => draft.merchant_id === user.id);
  const otherDraftSale = userDraftSale.filter(sale => sale.id !== currentBill.id);
  const draftSale = userDraftSale.filter(sale => sale.id === currentBill.id);

  const productsInStore = cloneArray(this.props.products);
  let productItemInStoreCopy = productsInStore.filter(
    (product) => product.id === item.id
  )[0];

  if(productItemInStoreCopy === undefined){
    message.warn("This Product is not available");
    return this.removeItemFromCart(item);
  }

  productItemInStoreCopy.quantity += 1;
  this.props.updateProductItemInStore(productsInStore, productItemInStoreCopy);

  if(item.variants){
    if (item.variants.length === 0) {
      if (item.quantity <= 1 ) return this.removeItemFromCart(item);
  
      const productItem = { ...item };
  
      const cartList = cloneArray(this.state.cartList);
      cartList.forEach((cartItem) => {
        if (cartItem.id === productItem.id) {
          cartItem.quantity = cartItem.quantity - 1;
          cartItem.amount = cartItem.price * cartItem.quantity;
        }
        this.setState({ cartList: [...cartList] });
      });
    } else {
      const price =
        item.variants.length > 0 ? item.variants[0].price : item.price;
      if (item.quantity === 1) return this.removeItemFromCart(item);
      item.variants.sort((a, b) => {
        return a.id < b.id;
      });
      const productItem = { ...item };
      const cartList = cloneArray(this.state.cartList);
      cartList.forEach((cartItem) => {
        cartItem.variants.sort((a, b) => {
          return a.id < b.id;
        });
        if (
          cartItem.id === productItem.id &&
          JSON.stringify(item.variants) === JSON.stringify(cartItem.variants)
        ) {
          cartItem.quantity = cartItem.quantity - 1;
          cartItem.amount = price * cartItem.quantity;
        }
        this.setState({ cartList: [...cartList] });
      });
  
      let list = [...cartList ]
      const total = list.reduce((prev, curr)=>{
        return prev + Number(curr.amount) 
      }, 0);
      draftSale[0].total = total;
  
      draftSale[0].transaction = [ ...cartList ];
      const newDraftSale = [ ...otherDraftSale, ...draftSale];
      localStorage.setItem("draftSales", JSON.stringify(newDraftSale));
    }
  
  }
  
};

export const addProductToCart = function (item, type = "single") {
    const { user, currentBill } = this.props;
    const draftSales = JSON.parse(localStorage.getItem("draftSales"));
    const userDraftSale = draftSales.filter(draft => draft.merchant_id === user.id);
    const otherDraftSale = userDraftSale.filter(sale => sale.id !== currentBill.id);
    const draftSale = userDraftSale.filter(sale => sale.id === currentBill.id);
    const productItem = { ...item };
    const productsInStore = cloneArray(this.props.products);
  
    let productItemInStoreCopy = productsInStore.filter(
      (product) => product.id === productItem.id
    )[0];
    
    if(productItemInStoreCopy === undefined){
      message.warn("This Product is not available");
      return;
    }
    const initialProductsData = this.props.products;
    const initialProductItemData = initialProductsData.filter(
      (product) => product.id === item.id
    )[0];
  
    const cartList = cloneArray(this.state.cartList);
  
    if (item.track_inventory) {
      if (Number(productItemInStoreCopy.quantity) < 1) {
        // TODO: flag error - product cannot be added to cart. [Refill product]
        message.warning(strings.productOutOfStock, toastMessageDuration);
        return;
      }
  
      productItemInStoreCopy.quantity =
        type === "single" || type === 1
          ? Number(productItemInStoreCopy.quantity) - 1
          : Number(initialProductItemData.quantity) -
            Number(productItem.quantity);

      this.props.updateProductItemInStore(
        productsInStore,
        productItemInStoreCopy
      );
    }
  
    // filter cartList array to check for the item
    const isItemInCartList = cartList.filter(
      (cartItem) => productItem.id === cartItem.id
    );
    // if the item does not exist
    if (isItemInCartList.length === 0) {
      if (!productItem.quantity || productItem.quantity <= 0) {
        productItem.quantity = 1;
      }
      const price =
        productItem.variants.length > 0
          ? productItem.variants[0].price
          : productItem.price;
      productItem.amount = price * productItem.quantity;
      productItem.points = productItem.points ? productItem.points : 0;
      productItem.stamps = productItem.stamps ? productItem.stamps : 0;
      productItem.product_id = productItem.id;
      productItem.business_branch_id = user.business_branch
        ? user.business_branch.id
        : null;
      this.setState({ cartList: [...cartList, productItem] });
      message.success(strings.productAdded, toastMessageDuration);

      let list = [...cartList, productItem]
      const total = list.reduce((prev, curr)=>{
        return prev + Number(curr.amount) 
      }, 0);
      draftSale[0].total = total;
      draftSale[0].transaction = [ ...cartList, productItem ];
      const newDraftSale = [ ...otherDraftSale, ...draftSale];
      localStorage.setItem("draftSales", JSON.stringify(newDraftSale));
    } else {
      if (item.variants.length === 0) {
        cartList.forEach((cartItem) => {
          if (cartItem.id === productItem.id) {
            if (type !== "bulk") {
              cartItem.quantity = parseInt(cartItem.quantity) + 1;
              cartItem.amount = cartItem.price * cartItem.quantity;
            } else {
              cartItem.quantity = productItem.quantity;
              cartItem.amount = cartItem.price * cartItem.quantity;
            }
          }
        });
        this.setState({ cartList: [...cartList] });
        message.success(strings.productAdded, toastMessageDuration);
        let list = [...cartList ]
          const total = list.reduce((prev, curr)=>{
            return prev + Number(curr.amount) 
          }, 0);
          draftSale[0].total = total;
        draftSale[0].transaction = [ ...cartList ];
        const newDraftSale = [ ...otherDraftSale, ...draftSale];
        localStorage.setItem("draftSales", JSON.stringify(newDraftSale));
      } else {
        productItem.variants.sort((a, b) => {
          return a.id < b.id;
        });
        const product = cartList.filter((cartItem) => {
          if (item.id === cartItem.id) {
            cartItem.variants.sort((a, b) => a.id < b.id);
            return (
              item.id === cartItem.id &&
              JSON.stringify(productItem.variants) ===
                JSON.stringify(cartItem.variants)
            );
          }
        });
        if (product.length === 1) {
          cartList.forEach((cartItem) => {
            if (cartItem.id === productItem.id) {
              cartItem.variants.sort((a, b) => a.id < b.id);
              if (
                JSON.stringify(productItem.variants) ===
                JSON.stringify(cartItem.variants)
              ) {
                const price =
                  cartItem.variants.length > 0
                    ? cartItem.variants[0].price
                    : cartItem.price;
                if (type === "bulk") {
                  cartItem.quantity = productItem.quantity;
                  cartItem.amount = price * cartItem.quantity;
                } else if (productItem.quantity > 1 && type !== 1) {
                  cartItem.quantity =
                    parseInt(cartItem.quantity) + productItem.quantity;
                  cartItem.amount = price * cartItem.quantity;
                } else if (productItem.quantity > 1 && type === 1) {
                  cartItem.quantity = parseInt(cartItem.quantity) + 1;
                  cartItem.amount = price * cartItem.quantity;
                } else {
                  cartItem.quantity = parseInt(cartItem.quantity) + 1;
                  cartItem.amount = price * cartItem.quantity;
                }
              }
            }
          });
          this.setState({ cartList: [...cartList] });
          message.success(strings.productAdded, toastMessageDuration);
          let list = [...cartList]
          const total = list.reduce((prev, curr)=>{
            return prev + Number(curr.amount) 
          }, 0);
          draftSale[0].total = total;
          draftSale[0].transaction = [ ...cartList ];
          const newDraftSale = [ ...otherDraftSale, ...draftSale];
          localStorage.setItem("draftSales", JSON.stringify(newDraftSale));
        } else {
          if (isNaN(productItem.quantity) || productItem.quantity <= 0) {
            productItem.quantity = 1;
          }
          const price =
            productItem.variants.length > 0
              ? productItem.variants[0].price
              : productItem.price;
          productItem.amount = price * productItem.quantity;
          productItem.points = 0;
          productItem.stamps = 0;
          productItem.product_id = productItem.id;
          productItem.business_branch_id = user.business_branch
            ? user.business_branch.id
            : null;
          this.setState({ cartList: [...cartList, { ...productItem }] });
          let list = [...cartList, { ...productItem }]
          const total = list.reduce((prev, curr)=>{
            return prev + Number(curr.amount) 
          }, 0);
          draftSale[0].total = total;
          draftSale[0].transaction = [ ...cartList, { ...productItem }];
          const newDraftSale = [ ...otherDraftSale, ...draftSale];
          localStorage.setItem("draftSales", JSON.stringify(newDraftSale));
          message.success(strings.productAdded, toastMessageDuration);
        }
      }
    }
  };

  export const handleAddBundleProductToCart = function (item, bulk) {
    message.destroy();
    const { user, currentBill } = this.props;
    const draftSales = JSON.parse(localStorage.getItem("draftSales"));
    const userDraftSale = draftSales.filter(draft => draft.merchant_id === user.id);
    const otherDraftSale = userDraftSale.filter(sale => sale.id !== currentBill.id);
    const draftSale = userDraftSale.filter(sale => sale.id === currentBill.id);

    const cartList = cloneArray(this.state.cartList);
  
    
    const isItemInCartList = cartList.filter(
      (cartItem) => item.id === cartItem.id
    );
  
    let quantity;
    let amount;
  
    if(isItemInCartList.length === 0){
      quantity = 1;
      amount = Number(item.price) * 1;
      const isQuantEnough = item.products.filter((prod, i) => prod.quantity !== null && Number(prod.quantity) < Number(item.quantity[i]));
  
      if(isQuantEnough.length === 1) return message.info(`${isQuantEnough[0].name} ${strings.oneBundleProductOutOfStock}`);
      if(isQuantEnough.length > 1) return message.info(strings.moreBundleProductOutOfStock)
      
      const newItem = {
        name: item.name,
        quantity,
        points: item.points ? item.points : 0,
        stamps: item.stamps ? item.stamps : 0,
        program_type: "SimplePoints",
        product_type: "product",
        amount,
        price: item.price,
        merchant_id: item.merchant_id,
        merchant_loyalty_program_id: item.merchant_loyalty_program_id ? item.merchant_loyalty_program_id : null,
        product_id: null,
        bundle_id: item.id,
        id: item.id,
        products: item.products,
        product_quantity: item.quantity,
        bundle_products: item.bundle_products
      }
      let list = [newItem, ...cartList ]
      const total = list.reduce((prev, curr)=>{
        return prev + Number(curr.amount) 
      }, 0);
      console.log("alllls", total);
      
      draftSale[0].total = total;
      draftSale[0].transaction = [ newItem, ...cartList ];
      const newDraftSale = [ ...otherDraftSale, ...draftSale];
      localStorage.setItem("draftSales", JSON.stringify(newDraftSale));
      this.setState({ cartList: [ newItem, ...cartList ]})
      message.success(strings.productAdded, toastMessageDuration);
    }else{
      const newCartList = cartList.filter(
        (cartItem) => item.id !== cartItem.id
      );
      let newQuantity;
      let newAmount;
      if(bulk === "bulk"){
        newQuantity = Number(item.quantity);
        newAmount = Number(item.price) * newQuantity;
      }else{
        newQuantity = isItemInCartList[0].quantity + 1;
        newAmount = Number(item.price) * newQuantity;
      }
  
      let isQuantEnough = [];
      if(item.product_quantity){
        isQuantEnough = item.products.filter((prod, i) => prod.quantity !== null && Number(prod.quantity) < Number(item.product_quantity[i]) * Number(newQuantity));
      }else{
        isQuantEnough = item.products.filter((prod, i) => prod.quantity !== null && Number(prod.quantity) < Number(item.quantity[i]) * Number(newQuantity));
      }
      
      if(isQuantEnough.length === 1) return message.info(`${isQuantEnough[0].name} ${strings.oneBundleProductOutOfStock}`);
      if(isQuantEnough.length > 1) return message.info(strings.moreBundleProductOutOfStock)
      
      message.success(strings.productAdded, toastMessageDuration);
      let list = [ { ...isItemInCartList[0], quantity: newQuantity, amount: newAmount },
      ...newCartList, ]
      const total = list.reduce((prev, curr)=>{
        return prev + Number(curr.amount) 
      }, 0);
      draftSale[0].total = total;
      draftSale[0].transaction = [  { ...isItemInCartList[0], quantity: newQuantity, amount: newAmount },
      ...newCartList, ];
      const newDraftSale = [ ...otherDraftSale, ...draftSale];
      localStorage.setItem("draftSales", JSON.stringify(newDraftSale));
      return this.setState({
        cartList: [
          { ...isItemInCartList[0], quantity: newQuantity, amount: newAmount },
          ...newCartList,
        ],
      });
    }
  
  }
  
  export const handleReduceBundleProductToCart = function (item) {
    message.destroy();
    const { user, currentBill } = this.props;
    const draftSales = JSON.parse(localStorage.getItem("draftSales"));
    const userDraftSale = draftSales.filter(draft => draft.merchant_id === user.id);
    const otherDraftSale = userDraftSale.filter(sale => sale.id !== currentBill.id);
    const draftSale = userDraftSale.filter(sale => sale.id === currentBill.id);
  
    if (Number(item.quantity) === 1) return this.handleRemoveBundleProductToCart(item);
    const cartList = cloneArray(this.state.cartList);
    const newCartList = cartList.filter((itm) => itm.id !== item.id);
    const newQuantity = Number(item.quantity) - 1;
    item.quantity = newQuantity;
    item.amount = newQuantity * Number(item.price);
    let list = [item, ...cartList ]
    const total = list.reduce((prev, curr)=>{
      return prev + Number(curr.amount) 
    }, 0);
      draftSale[0].total = total;
    draftSale[0].transaction = [ item, ...cartList ];
    const newDraftSale = [ ...otherDraftSale, ...draftSale];
    localStorage.setItem("draftSales", JSON.stringify(newDraftSale));
    return this.setState({ cartList: [ item, ...newCartList] });
  }
  
  export const handleRemoveBundleProductToCart = function (item) {
    message.destroy();
    const { user, currentBill } = this.props;
    const draftSales = JSON.parse(localStorage.getItem("draftSales"));
    const userDraftSale = draftSales.filter(draft => draft.merchant_id === user.id);
    const otherDraftSale = userDraftSale.filter(sale => sale.id !== currentBill.id);
    const draftSale = userDraftSale.filter(sale => sale.id === currentBill.id);
   
    const cartList = cloneArray(this.state.cartList);
    let list = cartList.filter((itm) => itm.id !== item.id)
    const total = list.reduce((prev, curr)=>{
      return prev + Number(curr.amount) 
    }, 0);
     draftSale[0].total = total;
    draftSale[0].transaction = cartList.filter((itm) => itm.id !== item.id);
    const newDraftSale = [ ...otherDraftSale, ...draftSale];
    localStorage.setItem("draftSales", JSON.stringify(newDraftSale));
    return this.setState({
      cartList: cartList.filter((itm) => itm.id !== item.id),
    });
  }