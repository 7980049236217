import { Button, Collapse, InputNumber, message, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sendAllInventory, sendInventory, sendInventoryWithVariant } from "../../../../../../redux/actions/productActions";
import strings from "../../../../../../strings";
import { CaretDownOutlined,  } from "@ant-design/icons";
import "./index.scss";


const Option = Select.Option;

const BulkSendInventoryModal = ({
  products,
  productIds,
  showModal,
  closeModal,
  cancelSelection,
  sendInventory,
  updateProducts,
  branches,
  sendInvType,
  sendAllInventory,
  sendInventoryWithVariant
}) => {
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [sendInventoryLoading, setSendInventoryLoading] = useState(false);
  const [sendInventoryPayload, setSendInventoryPayload] = useState([]);
  const [ quantity, setQuantity ] = useState(null);
  const [ productsToSend, setProductToSend ] = useState([]);
  const [ isActive, setIsActive ] = useState(false);

  useEffect(() => {
    const productsObj = {};
    for (let i of products) {
      productsObj[i.id] = i;
    }

    const productsSend = productIds 
      && productIds
      .map((id) => productsObj[id])
      .filter((prod) => prod.track_inventory && Number(prod.quantity) > 0);

      console.log("pro", productsSend);
      
      setProductToSend(productsSend);
  }, [productIds]);

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Amount to Send",
      dataIndex: "quantity",
      render: (val, record, index) => {
        if(record.variants.length > 0) {
          return <div style={{
            display: "flex",
            flexDirection:"column",
            gap: 2
          }}>
            {/* <DownOutlined /> */}
            <span style={{fontWeight: "500"}}>Variants  </span>


            {record.variants.map((variant, ind) => {
            return (
              <div style={{display: "flex", alignItems:"center",justifyContent: "space-between", width: "100%"}}>
                <span>{variant.type} - {variant.value} ({variant.initialQuant}) </span>
                <InputNumber
                  min={1}
                  max={Number(variant.initialQuant)}
                  defaultValue={1}
                  onChange={(val) => {
                    updateProductQuantity(record.id, val, variant.id);
                  }}
                />
              </div>
            )
          })}
          </div>
          
        }
        return <InputNumber
          min={1}
          max={val}
          defaultValue={1}
          onChange={(val) => {
            updateProductQuantity(record.id, val)
          }}
        />
    },
    },
    // {
    //   title: "Select variant to Send",
    //   dataIndex: "variants",
    //   render: (val, record) => {
    //     return val.length > 0 ? <Select placeholder={strings.searchForVariant} showSearch optionFilterProp="children" mode="multiple" size="large" style={{width: "100%"}}>
    //       {val.length > 0 && val.map((variant, index) => {
    //         return <Option key={index} value={variant.id}>{variant.type} - {variant.value}</Option>
    //       })}
    //     </Select> : null
    //   },
    // },
  ];

  const handleSendAllInventory = () => {
    if (!selectedBranch) {
      return Modal.error({ title: "Please select destination branch." });
    }
    if (!quantity) {
      message.info("Not specifying a quantity will send items with 0 quantity!");
    }

    const payload = {
      business_branch_id: selectedBranch,
      quantity: quantity || 0
    }
    
    setSendInventoryLoading(true);
    sendAllInventory(payload)
    .then((res) => {
        Modal.success({
          title: res.data.message || strings.inventorySentSuccessfully,
        });
        setSendInventoryLoading(false);
        updateProducts();
        closeModal();
    })
    .catch((err) => {
        setSendInventoryLoading(false);
        Modal.error({
          title: err?.response ? err?.response.data?.message || err.response.message : err?.message ? err.message : "An error occured while sending inventory",
        });
        closeModal();
        updateProducts();
    });
  }

  const handleSendInventory = () => {
    if (!selectedBranch) {
      return Modal.error({ title: "Please select destination branch." });
    }
    setSendInventoryLoading(true);

    //return console.log("produc", sendInventoryPayload);
    const sendProducts = sendInventoryPayload.map((payload) =>{
      if(payload.length && payload.length > 0) return sendInventoryWithVariant(payload);
      sendInventory(payload)}
    );

    Promise.all(sendProducts)
      .then((res) => {
        Modal.success({
          title: strings.inventorySentSuccessfully,
        });
        setSendInventoryLoading(false);
        updateProducts();
        cancelSelection();
        closeModal();
      })
      .catch((err) => {
        setSendInventoryLoading(false);
        Modal.error({
          title: err?.response ? err?.response.data?.message || err.response.message : err?.message ? err.message : "An error occured while sending inventory",
        });
        closeModal();
        updateProducts();
      });
  };

  const myBranches = branches.filter(
    (branch) => branch.name.toLowerCase() !== "ecommerce"
  );

  const updateProductQuantity = (id, quantity, variant_id) => {
    if (!quantity) return message.error("Please enter quantity");
    let produtcToUpdate = [...sendInventoryPayload];

    function searchAndUpdate(item) {
      if (Array.isArray(item)) {
        item.forEach(searchAndUpdate); 
      } else if (item && typeof item === 'object') {
        if (item.product_id === id && (variant_id === null || item.variant_id === variant_id)) {
          if(quantity > Number(item.initialQuant)) return message.warn("Quantity exceeds product quantity!")
          item.quantity = quantity;
        }
      }
    }

    searchAndUpdate(produtcToUpdate);
    setSendInventoryPayload(produtcToUpdate)

    // if (productUpdated) {
    //   console.log("Product successfully updated!");
    // } else {
    //   console.warn("No matching product found for update.");
    // }
  };

  useEffect(() => {
    const payload = productsToSend && productsToSend.map((product, index) => {
      
      if(product.variants.length > 0){
        return [
            ...product.variants.map((variant, i) => {
              variant.business_branch_id = selectedBranch;
              variant.variant_id = variant.id;
              variant.product_id = product.id;
              variant.type = variant.type;
              variant.value = variant.value;
              variant.price = variant.price;
              variant.cost_price =  variant.cost_price;
              variant.initialQuant = variant.quantity;
              variant.quantity = 1;
              delete variant.branch_product_id;
              delete variant.created_at;
              // delete variant.id;
              delete variant.picture;
              delete variant.updated_at;
              return variant;
            })
        ]
      }else return {
        quantity: 1,
        business_branch_id: selectedBranch,
        product_id: product.id,
      }
    });
    setSendInventoryPayload(payload);
  }, [productsToSend]);

  useEffect(() => {
    if(!selectedBranch) return;
    const payload = sendInventoryPayload && sendInventoryPayload.map((product) => {
      if(product.length > 0){
        return [
          ...product.map((variant, i) => {
            variant.business_branch_id = selectedBranch;
            return variant;
          })
        ]
      }else return {
        ...product,
        business_branch_id: selectedBranch,
      }
    });
    setSendInventoryPayload(payload);
  }, [selectedBranch]);

 
  const calculateTotalVariantQuantity = (product) => {
    const totalQuant = product.variants.reduce((prev, curr) => {
      return prev + Number(curr.initialQuant);
    }, 0)
    return totalQuant;
  }

  const handleAskSEnd = () => Modal.warning({
    title: "Are you sure you want to send inventory?",
    content: "Items sent cannot be reversed",
    onOk: () => sendInvType === "single" ? handleSendInventory() : handleSendAllInventory(),
    cancelText: "Cancel",
    closable: true
  })

  return (
    <Modal
      open={showModal}
      onCancel={closeModal}
      width={600}
      title="Send Bulk Inventory"
      footer={[
        <Button onClick={closeModal}>Cancel</Button>,
        sendInvType === "single" && <Button
          type="primary"
          onClick={handleAskSEnd}
          loading={sendInventoryLoading}
        >
          Send
        </Button>,
        sendInvType === "multiple" && <Button
          type="primary"
          onClick={handleAskSEnd}
          loading={sendInventoryLoading}
        >
          Send
        </Button>,
      ]}
    >
      <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
        <p style={{marginBottom: 5}}><b>Branch:</b></p>
        <Select
          showSearch
          placeholder={strings.searchForBranch}
          onChange={(val) => setSelectedBranch(val)}
          optionFilterProp="children"
          size="large"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {myBranches &&
            myBranches.map((branch) => (
              <Select.Option key={branch.id} value={branch.id}>
                {branch.name}
              </Select.Option>
            ))}
        </Select>
      </div>
      {sendInvType === "multiple" && <div>
        <InputNumber style={{width: "100%"}} placeholder={strings.quantity} size="large" onChange={(value) => setQuantity(value)} />
      </div>}
      {/* {sendInvType === "single" && <Table columns={columns} dataSource={productsToSend} />} */}
      <div>
      {/* {sendInvType === "single" && productsToSend.length > 0 && productsToSend.map((prod, index) => {
        return */}
         <Collapse
            bordered={false}
            defaultActiveKey={['0']} 
            expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} />}
            style={{
                background: "#fff",
                width: "100%"
            }}
            expandIconPosition="right"
            // key={index}
          >
             {productsToSend.map((prod, index) => (
              <Collapse.Panel key={index} style={{backgroundColor:"#f4f4f4", marginBottom: 10, borderRadius: 10}} header={(
                <div>
                  <p style={{margin:0, fontSize:12, fontWeight:"600"}}>{prod.name}</p>
                  <p style={{margin:0, fontSize:10}} >Total Quantity: {prod.variants.length > 0 ? calculateTotalVariantQuantity(prod): prod.quantity}</p>
                </div>
                )}>
                  {prod.variants.length > 0 && (
                    <div>
                      <div style={{display: "flex", alignItems: "center", justifyContent:"space-between"}}>
                        <p style={{margin:0, fontSize:12, fontWeight:"600"}}>Variants</p>
                        <p style={{margin:0, fontSize:12, fontWeight:"600"}}>Qty to send</p>
                      </div>
                      
                      {prod.variants.map((variant, index) => {
                        return (
                          <div key={index} style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap:15}}>
                            <p style={{margin: 0, fontSize:12}}>{variant.type} - {variant.value} ({variant.initialQuant})</p>
                            <InputNumber  
                              min={1}
                              max={Number(variant.initialQuant)}
                              defaultValue={1}
                              onChange={(val) => {
                                updateProductQuantity(prod.id, val, variant.id);
                              }}
                              size="small"
                              placeholder="Qty to send"
                            />
                          </div>
                        )
                      })}
                    </div>
                  )}
                  {prod.variants.length === 0 && (
                    <div>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap:15}}>
                        <p style={{margin: 0, fontSize:12}}>{prod.name} ({prod.quantity})</p>
                        <div style={{display: "flex", flexDirection: "column"}}>
                        <p style={{margin:0, fontSize:12, fontWeight:"600", textAlign:"right"}}>Qty to send</p>
                          <InputNumber  
                            min={1}
                            max={Number(prod.quantity)}
                            defaultValue={1}
                            onChange={(val) => {
                              updateProductQuantity(prod.id, val);
                            }}
                            size="small"
                            placeholder="Qty to send"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {/* Render specific product details */}
                {/* <p>{prod.description || "No description available."}</p> */}
              </Collapse.Panel>
            ))}
          </Collapse>
        
      {/* })} */}
      </div>
     
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  branches: state.branch.allBranches,
  error: state.error,
});
export default connect(mapStateToProps, {
  sendInventory,
  sendAllInventory,
  sendInventoryWithVariant
})(BulkSendInventoryModal);
