import React, { useEffect, useState, useRef } from 'react';
import { 
    Card, 
    Button,
    Table,
    Input,
    Tag,
    DatePicker,
    message
} from 'antd';
import { useQuery, useLazyQuery, useSubscription } from '@apollo/client';
import strings from '../../../../strings';
import { GET_DELIVERY_REQUEST, SUBSCRIBE_DELIVERY } from '../../../../constants';
import { connect } from 'react-redux';
import CustomDataTable from '../../../../components/CustomDataTable';
import {  
    SearchOutlined 
  } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import { Icon } from "@ant-design/compatible";
import moment from 'moment';
import DeliveryDetails from './partials/DeliveryDetails';

const { RangePicker } = DatePicker;
const Delivery = ({
    user,
    branches
}) => {
    const searchInput = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const homeBranch = localStorage.getItem("homebranch") ? JSON.parse(localStorage.getItem("homebranch")) : {id: null};
    const [ loading, setLoading ] = useState(false);
    const [ tableData, setTableData ] = useState([]);
    const [ search, setSearch ] = useState("");
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([]);
    const [ searchedData, setSearchedData ] = useState(null);
    const [ deliveryDetails, setDeliveryDetails ] = useState(null);
    const [ filteredDate, setFilteredDate ] = useState([]);
    const [ isFiltering, setIsFiltering ] = useState(false);
    const [ showDeliveryDetails, setShowDeliveryDetails ] = useState(false);
    const { data, loading: deliveryLoading, refetch } = useQuery(GET_DELIVERY_REQUEST, {
      variables: {
        merchant_id: user?.employer ? user?.employer.id : user.id,
        branch_id: user.business_branch ? user.business_branch.id : homeBranch.id
      }
    });
    

    const { data: inSData} = useSubscription(SUBSCRIBE_DELIVERY, {
      onData: (data)=>{
        console.log("datrat", data);
        // const newOrdered =  data.data.data.subscribeDelivery;
  
        // const newOrderState = [...savedOrders, data.data.data.subscribeDelivery]
        
        refetch();  
        },
        variables: { 
          merchant_id: user?.employer ? user?.employer.id : user.id,
          branch_id: user.business_branch && user.business_branch.id 
        }
    });

    useEffect(() => {
      if(data){
        const { deliverydata } = data.getDeliveryRequests;

        let newDeliveryData = [ ...deliverydata.filter(data => !isNaN(new Date(data.delivery_date).getTime()))]

        const sortedData = newDeliveryData.length > 0 && newDeliveryData.sort((a, b) => {
          if(isNaN(new Date(b.delivery_date).getTime()) || isNaN(new Date(a.delivery_date).getTime())) return;
          return new Date(b.delivery_date+ " " + b.delivery_time).getTime() - new Date(a.delivery_date+ " " + a.delivery_time).getTime();
        });

        setTableData(sortedData || []);
      }
    }, [data]);

    const column = [
        {
            title: strings.customerInformation,
            dataIndex: "recipient",
            render: (record) => {
                if(record){
                    const value = JSON.parse(record);
                    return (
                        <p>{value.name}: {value.mobile}</p>
                    )
                }else{
                    return <p>N/A</p>
                }
            },
            // ...getColumnSearchProps("recipient"),
        },
        {
            title: strings.deliveryAddress,
            dataIndex: "destinationInformation",
            render: (record) => (record ? <p>{JSON.parse(record).address}</p> : <p>N/A</p>),
            // ...getColumnSearchProps("destinationInformation"),
        },
        {
            title: strings.orderId,
            dataIndex: "order_id",
            render: (record) => (record ? <p>{`#${record}`}</p> : <p>N/A</p>),
            // ...getColumnSearchProps("order_id"),
            sorter: (a, b) => Number(a.order_id) - Number(b.order_id),
        },
        {
            title: strings.orderAmount,
            dataIndex: "order_amount",
            render: (record) => (record ? <p>{user.employer ? user.employer.currency : user.currency} {record}</p> : <p>N/A</p>),
            // ...getColumnSearchProps("order_amount"),
            sorter: (a, b) =>{
                return Number(a.order_amount) - Number(b.order_amount)
            },
        },
        {
            title: strings.deliveryAmount,
            dataIndex: "delivery_amount",
            render: (record) => (record ? <p>{user.employer ? user.employer.currency : user.currency} {record}</p> : <p>N/A</p>),
            // ...getColumnSearchProps("delivery_amount"),
            sorter: (a, b) => Number(a.delivery_amount) - Number(b.delivery_amount),
        },
        {
            title: strings.status,
            dataIndex: "status",
            render: (record) => (
                record.toLowerCase() === "delivered" ? 
                (<Tag color="green" key={record}>
                {record.toUpperCase()}
              </Tag>) : 
              (<Tag color="red" key={record}>
                {record.toUpperCase()}
              </Tag>)
            ),
            // ...getColumnSearchProps("status"),
        },
        {
            title: strings.createdDate,
            dataIndex: "delivery_date",
            render: (record) => (record ? <p>{record}</p> : <p>N/A</p>),
            // ...getColumnSearchProps("delivery_date"),
            sorter: (a, b) => {
                if(isNaN(new Date(a.delivery_date).getTime()) || isNaN(new Date(b.delivery_date).getTime())) return;
                return new Date(a.delivery_date+ " " + a.delivery_time).getTime() - new Date(b.delivery_date+ " " + b.delivery_time).getTime();
            },
        },
        {
            title: strings.createdTime,
            dataIndex: "delivery_time",
            render: (record) => (record ? <p>{record}</p> : <p>N/A</p>)
        }
    ]

    function getColumnSearchProps(dataIndex){
        return({
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={searchInput}
                placeholder={`${strings.search} ${dataIndex}`}
                value={selectedKeys[0] || search}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                {strings.search}
              </Button>
              <Button
                onClick={() => handleReset(clearFilters, confirm)}
                size="small"
                style={{ width: 90 }}
              >
                {strings.reset}
              </Button>
            </div>
          ),
          filterIcon: (filtered) => (
            <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
          ),
          onFilter: (value, record) => {
            if (!record[dataIndex]) return;
            return record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => searchInput.current.select());
            }
          },
          render: (text) => (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[search]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ),
      })};

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      setSearch(selectedKeys[0]);
      // setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
      clearFilters();
      confirm();
      setSearch("");
      // setSearchedColumn("")
      setSearchedData(null);
    };
    
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleShowDetails = (rowData) => {
      setDeliveryDetails(rowData);
      setShowDeliveryDetails(true);
    };

    const handleDurationChange = async (duration, ...rest) => {
      if (duration) {
        const [begin, end] = duration;
        setIsFiltering(true);
        const startDate = new Date(begin);
        const endDate = new Date(end);
  
        setIsLoading(false);
        const dateFilterDeilveries = tableData.filter(delivery => {
          const deliveryDate = new Date(delivery.delivery_date.split('-').reverse().join('-'));
          return deliveryDate >= startDate && deliveryDate <= endDate;
        });
        setFilteredDate(dateFilterDeilveries);
        setIsLoading(false);
       
      } else if (!duration) {
        refetch();
        setIsFiltering(false);
        setFilteredDate([]);
      } 
      // else if (!begin || !end) {
      //   setIsFiltering(false);
      //   message.error(
      //     `${strings.pleaseEnterAValid} ${!begin ? strings.begin : strings.end} ${
      //       strings.date
      //     }.`
      //   );
      // }
    };
    

    const isAdmin = user.role_id && user.role_id === 1;
  return (
    <Card
      title={strings.deliveryRequests}
      extra={
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ margin: "0 10px" }}>{strings.reportFrom}:</span>
          <RangePicker
            placeholder={[strings.startDate, strings.endDate]}
            onChange={handleDurationChange}
            allowClear="false"
          />
        </div>
      }
    >
        <CustomDataTable 
            loading={deliveryLoading || loading}
            columns={column}
            dataSource={isFiltering ? filteredDate : tableData}
            // rowSelection={rowSelection}
            onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleShowDetails(record);
                  },
                };
            }}
        />

        {showDeliveryDetails && (
          <DeliveryDetails 
            open={showDeliveryDetails}
            onCancel={() => setShowDeliveryDetails(false)}
            delivery={deliveryDetails}
            refetch={refetch}
            user={user}
            branches={branches}
          />
        )}
    </Card>
  )
}

const mapStateToProps = state => ({
    user: state.auth.user,
    allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
    branches: state.branch.allBranches,
  });
  
  export default connect(mapStateToProps, {
    
  })(Delivery);
