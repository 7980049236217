import { Checkbox, Input, InputNumber } from "antd";

export const PaymentLabelContent = ({
  splitPaymentActive,
  allPaymentLabels,
  totalAmount,
  amountRemaining,
  setAmountRemaining,
  setSelectedPaymentLabel,
  selectedPaymentLabel,
  strings,
  setTotalAmount,
  valueToRemain,
  totalAmountToBePaid,
  splitPaymentMethods,
  message
}) => {
  return (
    <div>
    <div>{strings.hasPaymentBeenMade}</div>
    <div className="mt-2">
      {splitPaymentActive && (
        <div style={{ marginTop: 10 }}>{strings.amountPaidSplit}</div>
      )}
      {splitPaymentActive && (
        <InputNumber
          type="text"
          id="transfer-reference-input"
          // className="mt-2"
          placeholder={strings.amountPaidSplit}
          required
          value={
            splitPaymentMethods.method1.method !== null
              ? Number(totalAmount).toFixed(2)
              : Number(amountRemaining).toFixed(2)
          }
          onChange={(value) => {
            if (
              splitPaymentMethods.method1.method === null &&
              value >= Number(totalAmountToBePaid)
            )
              return message.error(
                "Cannot use split payment on complete amount"
              );
            setAmountRemaining(value);
            valueToRemain = value;
            setTotalAmount(totalAmountToBePaid - value);
          }}
          disabled={splitPaymentMethods.method1.method !== null}
          style={{ width: "100%" }}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          // gap: "10px",
          marginBottom: "5px",
        }}
      >
        {allPaymentLabels.map((labels, index) => {
          return (
            <p
              key={index}
              style={{
                padding: "5px 10px",
                border: "1px solid #f5f5f5",
                cursor: "pointer",
                backgroundColor: selectedPaymentLabel === labels.id ? "#D90068" : "#fff",
                color: selectedPaymentLabel === labels.id ? "#fff" : "#000",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "center"
              }}
              onClick={(e) => {
                  setSelectedPaymentLabel(labels.id);
              }}
            >
              {labels.label_name}
            </p>
          );
        })}
      </div>
      <Input
        type="text"
        id="transfer-reference-input"
        className="mt-1"
        placeholder={strings.transactionReference}
        required
      />
    </div>
  </div>
  );
};
